import React, {useState} from 'react';
import {db, storage, firebase} from '../../services/firebase';
import DateTimePicker from 'react-datetime-picker';
import "./styles.css";

export default function EditSponsor({editSponsorId}) {
    const [sponsorTitle, setSponsorTitle] = useState("");
    const [sponsorSubHeader, setSponsorSubHeader] = useState("");
    const [sponsorBody, setSponsorBody] = useState("");
    const [linkName, setLinkName] = useState("");
    const [link, setLink] = useState("");
    const [locationInfo, setLocationInfo] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [contentUrl, setContentUrl] = useState("");
    const [preImgUrl, setPreImgUrl] = useState("");
    const [ccUrl, setCCUrl] = useState("");
    const [categoryValue, setCategoryValue] = useState("");
    const [commSubHeader, setCommSubHeader] = useState("");
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [geopoints, setGeopoints] = useState([
        { latitude: '', longitude: '', id: 0},
    ]);
    const [pin, setPin] = useState();

    const [progress, setProgress] = useState(0);
    const [progressPre, setProgressPre] = useState(0);
    const [progressPlay, setProgressPlay] = useState(0);
    const [progressCC, setProgressCC] = useState(0);

    const [randomKey, setRandomKey] = useState("");
    const [randomKeyPre, setRandomKeyPre] = useState("");
    const [randomKeyPlay, setRandomKeyPlay] = useState("");
    const [randomKeyCC, setRandomKeyCC] = useState("");

    const [newImageName, setNewImageName] = useState("");
    const [newPreName, setNewPreName] = useState("");
    const [newPlayName, setNewPlayName] = useState("");
    const [newCCName, setNewCCName] = useState("");

    const [updateMainImg, setUpdateMainImg] = useState(false);
    const [updatePreImg, setUpdatePreImg] = useState(false);
    const [updatePlay, setUpdatePlay] = useState(false);
    const [updateCC, setUpdateCC] = useState(false);


    const [error, setError]= useState(false);
    const [errorTip, setErrorTip] = useState(false);
    const [communitySpan, setCommunitySpan] = useState();

    let millisecondsStart;
    let  millisecondsEnd;
    let  startDate;
    let  endDate;
    let  startDateFormat;
    let  endDateFormat;


    if(editSponsorId == null){
        return null;
    }

    const clearInputs = () => {
        setSponsorTitle('');
        setSponsorSubHeader('');
        setSponsorBody('');
        setLinkName('');
        setLink('');
        setLocationInfo('');
        setImgUrl('');
        setPreImgUrl('');
        setContentUrl('');
        setCCUrl('');
        setCategoryValue('');
        setCommSubHeader('');
        setStart('');
        setEnd('');
        setPin('');
        setProgress(0);
        setProgressPre(0);
        setProgressPlay(0);
        setProgressCC(0);
        setGeopoints([{ latitude: '', longitude: '', id: 0},]);
        setError(false);
        setErrorTip(false);
        
    }


    let geoCounter = 0;

    const handleChangeInput = (id, e) => {
        
        const values = [...geopoints];
        values[id][e.target.name] = e.target.value;
        setGeopoints(values);
    }

    const handleAddFields = () => {
        setGeopoints([...geopoints, {latitude: '', longitude: '' , id: geoCounter++}])
    }

    const handleRemoveFields = (id) => {
        if( id > 0){
        const values = [...geopoints];
        values.splice(id,  1);
        setGeopoints(values);
        }else {
            return false;
        };
    };

    const handleCategoryChange = (e) =>{
    
        if(e.target.name){
            setCategoryValue(e.target.value);
            console.log(e.target.value);
        }
        if(e.target.value==="community"){
            setCommunitySpan(true);
        }else{
            setCommunitySpan(false);
        }
    };

    const changePin = () => {
        editSponsorId.sponsor.pinned=!editSponsorId.sponsor.pinned;
        setPin(editSponsorId.sponsor.pinned);
    }

    const updateTitle = (e) => {
        if(e.target.value == ''){
            setSponsorTitle(' ');
        }
        else{
            setSponsorTitle(e.target.value)
        }
    }

    const updateSubHeader = (e) => {
        if(e.target.value == ''){
            setSponsorSubHeader(' ');
        }
        else{
            setSponsorSubHeader(e.target.value)
        }
    }


    const updateBody = (e) =>{
        if(e.target.value == ''){
            setSponsorBody(' ');  
        }
        else{
            
            setSponsorBody(e.target.value)     
        }
    }
    const updateLocationInfo = (e) => {
        if(e.target.value==''){
            setLocationInfo(' ')
        }
        else{
            setLocationInfo(e.target.value)
        }
    }

    const updateLinkName = (e) =>{
        if(e.target.value==''){
            setLinkName(' ')
        }
        else{
            setLinkName(e.target.value)
        }
    }

    const updateLink = (e) =>{
        if(e.target.value == ''){
            setLink(' ')
        }
        else{
            setLink(e.target.value)
        }
    }

    const updateCommSubHeader = (e) =>{
        if(e.target.value==''){
            setCommSubHeader(' ')
        }
        else{
            setCommSubHeader(e.target.value)
        }
    }



    try{
        millisecondsStart= editSponsorId.sponsor.start.toDate()
        millisecondsEnd = editSponsorId.sponsor.end.toDate()
        startDate = new Date(millisecondsStart)
        endDate = new Date(millisecondsEnd)
        startDateFormat = startDate.toLocaleString("en-US",  {timeZoneName: "short"})
        endDateFormat = endDate.toLocaleString("en-US", {timeZoneName: "short"})
    
    
        }catch{
            return null;
    
        }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        }
    
    
    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     };
    
     //Main Image//
     const handleImgUpdate = async (e) => {
        var imageName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`images/${imageName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
                setUpdateMainImg(true);
            },
            error => {
                console.log(error);
            },
        )
        setImgUrl(await fileref.getDownloadURL())   
        setNewImageName(imageName);
    }

    const removeMainImgDb = () => {
        var imageRef = storage.refFromURL(editSponsorId.sponsor.mainPhotoUrl)
        console.log(imageRef)
        imageRef.delete().then(function(){
            console.log("delete main image success");
        }).catch(function(error){
            console.log(`Error ${error}`);
        });
        
    }

    const handleRemoveMain = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`images/${newImageName}`)
            try {file.delete().then(function(){
                console.log("Main Image deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKey(randomString);
         setProgress(0);
         setUpdateMainImg(false);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }
    //Preview Image//
    const handlePreImgUpdate = async (e) => {
        var imagePreName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`images/${imagePreName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progressPre = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgressPre(progressPre);
                setUpdatePreImg(true);
            },
            error => {
                console.log(error);
            },
        )
        setPreImgUrl(await fileref.getDownloadURL())
        setNewPreName(imagePreName)
    }

    const removePreImgDb = () => {
        var imageRef = storage.refFromURL(editSponsorId.sponsor.previewPhotoUrl)
        console.log(imageRef)
        imageRef.delete().then(function(){
            console.log("delete preview Db success");
        }).catch(function(error){
            console.log(`Error ${error}`);
        });
        
    }

    const handleRemovePreImg = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`images/${newPreName}`)
            try {file.delete().then(function(){
                console.log("Preview Image deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKeyPre(randomString);
         setProgressPre(0);
         setUpdatePreImg(false);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }

    //Play//
    const handlePlayUpdate = async (e) => {
        var playName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`playContent/${playName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progressPlay = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgressPlay(progressPlay);
                setUpdatePlay(true);
            },
            error => {
                console.log(error);
            },
        )
        setContentUrl(await fileref.getDownloadURL())
        setNewPlayName(playName)
    }
    const removePlayDb = () => {
        var imageRef = storage.refFromURL(editSponsorId.sponsor.playUrl)
        console.log(imageRef)
        imageRef.delete().then(function(){
            console.log("delete Play from DB success");
        }).catch(function(error){
            console.log(`Error ${error}`);
        });
        
    }

    const handleRemovePlay = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`playContent/${newPlayName}`)
            try {file.delete().then(function(){
                console.log("Play deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKeyPlay(randomString);
         setProgressPlay(0);
         setUpdatePlay(false);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }

    //Transcript//
    const handleTranscriptUpdate = async (e) => {
        var transcriptName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`transcripts/${transcriptName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progressCC = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgressCC(progressCC);
                setUpdateCC(true);
            },
            error => {
                console.log(error);
            },
        )
        setCCUrl(await fileref.getDownloadURL())
        setNewCCName(transcriptName)

    }
    const removeCCDb = () => {
        var imageRef = storage.refFromURL(editSponsorId.sponsor.TranscriptUrl)
        console.log(imageRef)
        imageRef.delete().then(function(){
            console.log("delete transcript from DB success");
        }).catch(function(error){
            console.log(`Error ${error}`);
        });
        
    }

    const handleRemoveTranscript = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`transcripts/${newCCName}`)
            try {file.delete().then(function(){
                console.log("Transcript deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKeyCC(randomString);
         setProgressCC(0);
         setUpdateCC(false);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }



     const handleUpdate = (e) => {
        if(sponsorTitle != ' ' && start != ' ' && end != ' ' && categoryValue != ' '  && imgUrl != ' ' && contentUrl != ' ' && geopoints != ' '){
            if (window.confirm('Are you sure you wish to update this play?')){
                if(updateMainImg==true){
                    removeMainImgDb();
                }
                if(updatePreImg==true){
                    removePreImgDb();
                }
                if(updatePlay==true){
                    removePlayDb();
                }
                if(updateCC==true){
                    removeCCDb();
                }
         db.collection("content").doc(editSponsorId.id).update({
            // timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            title: sponsorTitle || editSponsorId.sponsor.title,
            subHeader: sponsorSubHeader || editSponsorId.sponsor.sponsorSubHeader,
            body: sponsorBody || editSponsorId.sponsor.body,
            mainPhotoUrl: imgUrl || editSponsorId.sponsor.mainPhotoUrl,
            previewPhotoUrl: preImgUrl || editSponsorId.sponsor.previewPhotoUrl,
            playUrl: contentUrl || editSponsorId.sponsor.playUrl,
            TranscriptUrl: ccUrl || editSponsorId.sponsor.TranscriptUrl,
            linkName: linkName || editSponsorId.sponsor.linkName,
            link: link || editSponsorId.sponsor.link,
            locationInfo: locationInfo || editSponsorId.sponsor.locationInfo,
            category: categoryValue || editSponsorId.sponsor.category,
            commSubHeader: commSubHeader || editSponsorId.sponsor.commSubHeader,
            start: start || editSponsorId.sponsor.start,
            end: end || editSponsorId.sponsor.end,
            geopoints: firebase.firestore.FieldValue.arrayUnion(...geopoints) || editSponsorId.sponsor.geopoints,
            pinned: pin || editSponsorId.sponsor.pinned,

           
         });
        } else {
            return false;
        }
         clearInputs();
         var randomString = makeid(9)

         setRandomKey(randomString);
         setRandomKeyPre(randomString);
         setRandomKeyPlay(randomString);
         setRandomKeyCC(randomString);
         scrollToTop();
 
     }else {
         setError("You are missing one or more components.");
         setErrorTip("*");
     }
    };
 
     const removeGeo = (id,lat,long) => {
         console.log(id);
         let array = [editSponsorId.sponsor.geopoints];
         const obj = {id:id, latitude: lat, longitude: long}
         db.collection("content").doc(editSponsorId.id).update({
             geopoints: firebase.firestore.FieldValue.arrayRemove(obj)
         });
         
      }
    
    return (
        <div className="CreateSponsor sponsor">
           <div>
               <h2>Sponsor Content</h2>
               <div>
               <label className="errorMsg">{errorTip}</label>
               <label>Title</label>
               <input className="form-control form-control-lg" type="text" placeholder={`${editSponsorId.sponsor.title}`} aria-label=".form-control-lg example"
                        value={sponsorTitle || editSponsorId.sponsor.title}
                        onChange={updateTitle}
                    ></input>
                <br></br>
                <label>Optional: Subheader</label>
                <input className="form-control form-control-md" type="text" placeholder={`${editSponsorId.sponsor.subHeader}`} aria-label=".form-control-lg example"
                        value={sponsorSubHeader || editSponsorId.sponsor.sponsorSubHeader}
                        onChange={updateSubHeader}
                    ></input>
                    <br></br>
                <div className="mb-3">
                <label>Body</label>
                    <textarea className="form-control" placeholder={`${editSponsorId.sponsor.body}`} rows="3"
                        value={sponsorBody || editSponsorId.sponsor.body}
                        onChange={updateBody}
                    ></textarea>
                    </div>
                    <div className="mb-3">
                    <label>Location Info/ Address</label>
                    <textarea className="form-control" placeholder={`${editSponsorId.sponsor.locationInfo}`} rows="2"
                        value={locationInfo || editSponsorId.sponsor.locationInfo}
                        onChange={updateLocationInfo}
                    ></textarea>
                    </div>
                    <div className="mb-3">
                    <label className="errorMsg">{errorTip}</label>
                    <label htmlFor="file-input" className="form-label">Main Image</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        accept="image/*"
                        key= {randomKey || ''}
                        onChange={handleImgUpdate}
                    />
                    <progress max="100" value={progress}></progress>
                    <br/>
                   <button type="submit" className="btn btn-danger mb-3" onClick={handleRemoveMain}>Cancel</button>
                    </div>
                    </div>
                    <div className="mb-3">
                    <label htmlFor="file-input" className="form-label">Preview Image</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        accept="image/*"
                        key= {randomKeyPre || ''}
                        onChange={handlePreImgUpdate}
                    />
                      <progress max="100" value={progressPre}></progress>
                      <br/>
                    <button type="submit" className="btn btn-danger mb-3" onClick={handleRemovePreImg}>Cancel</button>
                    </div>
                <div className="mb-3">
                <label className="errorMsg">{errorTip}</label>
                    <label htmlFor="file-input" className="form-label">Choose Video or Audio to upload</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        accept="video/*, audio/*"
                        key= {randomKeyPlay || ''}
                        onChange={handlePlayUpdate}
                    />
                     <p>This may take awhile. . .</p>
                    <progress max="100" value={progressPlay}></progress>
                    <br/>
                   <button type="submit" className="btn btn-danger mb-3" onClick={handleRemovePlay}>Cancel</button>
                    </div>
                    <div className="mb-3">
                    {/* <label className="errorMsg">{errorTip}</label> */}
                    <label htmlFor="file-input" className="form-label">Choose Transcript file to upload</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        key={randomKeyCC || ''}
                        onChange={handleTranscriptUpdate}
                    />
                    <progress max="100" value={progressCC}></progress>
                    <br/>
                   <button type="submit" className="btn btn-danger mb-3" onClick={handleRemoveTranscript}>Cancel</button>
                    </div>
                <br></br>
                <div>
                <label>Link Name</label>
                    <input className="form-control form-control-md" type="text" placeholder={`${editSponsorId.sponsor.linkName}`} aria-label=".form-control-lg example"
                        value={linkName || editSponsorId.sponsor.linkName}
                        onChange={updateLink}
                    ></input>
                    <label>Link Url</label>
                    <input className="form-control form-control-md" type="url" pattern="https?://.+" required placeholder={`https:// ${editSponsorId.sponsor.link}`} aria-label=".form-control-lg example"
                        value={link || editSponsorId.sponsor.link}
                        onChange={updateLinkName}
                    ></input>
                    </div>
                    <br></br>
                <div>
                <label className="errorMsg">{errorTip}</label>
                    <label>Sponsor Start Date & Time</label>
                    <br/>
                    <label>Current: {`${startDateFormat}`}</label>
                    <DateTimePicker className="btn-space form-control-md" onChange={setStart} value={start} />
                </div>
                <div>
                <label className="errorMsg">{errorTip}</label>
                    <label>Sponsor End Date & Time</label>
                    <br/>
                    <label>Current: {`${endDateFormat}`}</label>
                    <DateTimePicker className="btn-space form-control-md" onChange={setEnd} value={end} />
                </div>
                <br></br>
                {/* Category Section */}
                <div onChange={handleCategoryChange}>
                <label className="errorMsg">{errorTip}</label>
                {/* <label>Current Filter: {filterName}</label> */}
                        <div className="form-check">
                        <input className="form-check-input" type="radio" name="category" value="friday"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                First Friday </label>
                            </div>
                        <div className="form-check">
                        <input className="form-check-input" type="radio" name="category" value="community"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Community </label>
                            </div>
                    </div>

                    {communitySpan == true && (
                         <span>
                        <label>Community SubHeader</label>
                        <input className="form-control form-control-md" type="text" aria-label=".form-control-lg example"
                         maxLength="50"
                         value={commSubHeader || editSponsorId.sponsor.commSubHeader}
                         placeholder={`${editSponsorId.sponsor.commSubHeader}`}
                         onChange={updateCommSubHeader}
                    ></input></span>
                    )}
                    <br></br>
                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" 
                    // value={pin} 
                    checked ={editSponsorId.sponsor.pinned}
                    onChange={changePin}
                     />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                        Pin this post to the top.
                    </label>
                    </div>
                    <br></br>

                <h6>Geopoints</h6>
                <div>
                {editSponsorId.sponsor.geopoints.map(geopoint => (
                    <>
                        {(function () {
                             for(let i = 0; i<editSponsorId.sponsor.geopoints.length;i++){
                                if(geopoint.latitude != `` && geopoint.longitude != ``){
                                    return(
                                        <>
                                        <p>Latitude: {geopoint.latitude} | Longitude: {geopoint.longitude}<button type="button"
                                            onClick={()=> removeGeo(geopoint.id,geopoint.latitude,geopoint.longitude)}>Delete</button></p> 
                                            <hr/>
                                        </>
                                    )
                                }
                            }
                        })()}
                       
                    </>
                ))}
            </div>
                { geopoints.map((geopoint, id)=> (
                    <div className="sponsors__geopoints_div" key={id}>
                        <label>Latitude</label>
                        <input 
                        className="form-control form-control-md sponsors__geopoints"
                        name="latitude" 
                        label="Latitude" 
                        value={geopoint.latitude} 
                        type="number"
                        onChange={(e) => handleChangeInput(id, e)}
                        
                        />
                        <label>Longitude</label>
                        <input  
                        className="form-control form-control-md sponsors__geopoints"
                        name="longitude" 
                        label="Longitude" 
                        value={geopoint.longitude} 
                        type="number"
                        onChange={(e) => handleChangeInput(id, e)}
                        
                        />
                        
                        <a title="Remove" className="btn btn-danger btn-space" onClick={()=> handleRemoveFields(id)}>
                            <i className="fas fa-minus"></i>
                        </a>
                        <a title="Add" className="btn btn-success btn-space" onClick={()=> handleAddFields()}>
                            <i className="fas fa-plus"></i>
                        </a>
                        
                    </div>
                )) }
                
                <br></br>
                <label className="errorMsg">{error}</label>
                <br/>
                    <div className="col-auto">
                        <button type="button" className="btn btn-warning mb-3" onClick={handleUpdate}>Update</button>
                    </div>

               </div>


           </div>
    )
}
