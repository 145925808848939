import React, {useState, useEffect, useReducer} from 'react'
import { db, storage } from '../../services/firebase';
import { Link } from 'react-router-dom';
import "./styles.css";


export default function PostList({
    id, 
    postTitle, 
    postBody, 
    link,
    linkName,
    category,
    subHeader,
    pin,
    // fileUrl,
    setDbPublish,
    photoUrl, 
    timestamp, 
    postId, 
    setPostId, 
    editPostId, 
    setEditPostId}) {

        const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

        const [publish, setPublish] = useState(setDbPublish); 

        const changePublish = () => {
        
          setPublish(!publish);
   
        }


        useEffect(() => {
            db.collection("posts").doc(id).update({
                publish: publish,
               

            });
            // console.log(publish)
        }, [publish])
        
        //Trying to refresh page after Edit Post click to reload the edit form data.
        const refreshPage = ()=>{
            window.location.reload();
         }

    //Actions:
    const previewPost = () => {
        
        db.collection("posts").doc(id).onSnapshot((doc) =>{
            
            setPostId({id:doc.id, post: doc.data()});
            // console.log(postId);
        });
    };

    const editPost = () => {
        
        db.collection("posts").doc(id).onSnapshot((doc) =>{
            setEditPostId({id:doc.id, post:doc.data()});
            // console.log(editPostId);
        });
        forceUpdate();
        
    };

    //delete button? with an "are you sure you want to do that?"
    const deletePost = () => {
        if (window.confirm('Are you sure you wish to delete this item? This cannot be undone.')){
        //delete post and image
        //get ref to the image file
        
        // 
        if(photoUrl != ""){
        var imageRef = storage.refFromURL(photoUrl);
        try {imageRef.delete().then(function(){
            console.log("delete success");
        }).catch(function(error){
            console.log(`Error ${error}`);
        });
        }catch(error){
            console.log(`Error ${error}`);
        } 
        }  
        //2 delete the post info from firestore
        db.collection("posts").doc(id).delete().then(function(){
            console.log("delete post info successfully");
        }).catch(function(error){
            console.log(`Error post info ${error}`);
        });
    } else {
        return false;
    }

    };

  return (
        <>
            <tbody>
            {/* <a className="btn text-primary" title="Preview" > */}
                <tr>
                    {/* <td>{timestamp}</td> */}
                    <td className="post_title">{postTitle}</td>
                    <td className="post__overflow">{postBody}</td>
                    <td><img style={{width: "100px", objectfit: "contain", borderRadius: "7%"}} alt="" src={photoUrl}></img></td>
                    <td className="post_action">
                    <a className="btn text-success" title="Publish" defaultValue={setDbPublish}>
                            
                            {publish ? (
                                <>
                                    <span onClick={changePublish}><i className="fas fa-heart"></i></span>
                                </>
                            ) : (
                                <>
                                   <span onClick={changePublish}><i className="far fa-heart"></i></span>
                                </> 
                            )
                            }
                           
                        </a>
                        <Link to="/ShowPost"><a className="btn text-primary" title="Preview" onClick={previewPost}>
                            <i  className="fas fa-eye"></i>
                        </a></Link>
                        <Link to="/EditPost"><a title="Edit" className="btn text-warning" onClick={editPost}>
                            <i  className="fas fa-pencil-alt"></i>
                        </a></Link>
                        <a title="Delete" className="btn text-danger" onClick={deletePost} >
                            <i  className="fas fa-trash-alt"></i>
                        </a>
                    </td>
                    {/* <td>hello</td> */}
                    
                </tr>
                
            </tbody>
        </>
  );
}                        
