import React from 'react';
import {Header, Side, Footer} from './components';
import PrivateRoute from "./services/PrivateRoute"
import {
    BrowserRouter as Router,
    Route,
  } from 'react-router-dom';
import * as ROUTES from './services/routes';

import PostsPage from './pages/Posts';
import PlaysPage from './pages/Plays';
import UsersPage from './pages/Users';
import EventsPage from './pages/Events';
import SponsoredPage from './pages/Sponsored';
import DashboardPage from './pages/Dashboard';
import PaymentPage from './pages/Payment';



export default function Home() {
    return(
        <>
          <header><Header/></header>
          {/* <h1>Montana Repertory Theatre GoPlay! Dashboard</h1> */}
          
          <aside className="app__aside aside_background"><Side /></aside>
          <section className="app__section">
          <p>{process.env.REACT_APP_FIREBASE_DOMAIN}</p>

                <Route path={ROUTES.DASHBOARD} component={DashboardPage} />
                <Route path={ROUTES.POSTS} component={PostsPage} />
                <Route path={ROUTES.PLAYS} component={PlaysPage} />
                <Route path={ROUTES.USERS} component={UsersPage} />
                <Route path={ROUTES.EVENTS} component={EventsPage} />
                <Route path={ROUTES.SPONSORED} component={SponsoredPage} />
                <Route path={ROUTES.PAYMENT} component={PaymentPage} />
                <Route exact path="/" component={DashboardPage}/>
              

            </section>
          <footer className="app__footer"><Footer /></footer>
        </> 
    );
};
