import React, {useState, useEffect} from 'react';
import { db } from '../../services/firebase';
import DashList from './DashList';
import "./styles.css"

export default function DashFeed({
    dashId, 
    setDashId}) {

        const [feed, setFeed] = useState([]);
        

        useEffect(() => {
             db.collection("content").where("type", "in", ['play', 'sponsor', 'easterEgg']).limit(10).orderBy("timestamp", "desc")
            .onSnapshot((snapshot) =>{
                setFeed(snapshot.docs.map((doc)=> ({id:doc.id, dash: doc.data() })));
            })
               
        }, [])

  return (
    <div className="dashFeed">
        <div>
        <div>
            {/* <div>List of Dashs</div> */}
        </div>
        <div className="table table-borderless">
            <thead>
                <tr>
                    {/* <th>Date</th> */}
                    <th>Plays and Sponsor Videos</th>
                    <th>Body</th>
                    <th>Photo Preview</th>
                    <th>Views</th>
                </tr>
            </thead>  

            {feed.map(({id, dash, })=>{
                return ( <DashList
                key={id}
                id={id}
                dashTitle={dash.title}
                photoUrl={dash.mainPhotoUrl}
                dashBody={dash.body}
                playView={dash.views}
                dashId={dashId}
                setDashId={setDashId}
                 />
               
            );
            })}
           
             </div>

    </div>
        </div>
  );
}
