import React from 'react';
import "./styles.css"

export default function ShowEvent({eventId}) {
    if(eventId == null){
        return null;
    }

    let millisecondsStart;
    let  millisecondsEnd;
    let  startDate;
    let  endDate;
    let  startDateFormat;
    let  endDateFormat;
    let startFormat;


    try{
    millisecondsStart= eventId.event.start.toDate()
    millisecondsEnd = eventId.event.end.toDate()
    startDate = new Date(millisecondsStart)
    endDate = new Date(millisecondsEnd)
    startFormat = startDate.toLocaleDateString("en-US")
    startDateFormat = startDate.toLocaleString("en-US",  {timeZoneName: "short"})
    endDateFormat = endDate.toLocaleString("en-US", {timeZoneName: "short"})
    }catch{
        return null;
    }
    
    return (
        <div>
        <div className="event">
            <div className="event_container">
                <div className="event_time">
                    <h5>{`${startFormat}`}</h5>
                </div>
                     <div className="event__headerLeft"><p>{eventId.event.title}</p></div>
                        <div className='cropped'>
                        <img style={{
                            width: "100%",
                            objectfit: "contain",
                        }} alt="" src={eventId.event.photoUrl || "https://festivalofnationsstl.org/wp-content/themes/consultix/images/no-image-found-360x250.png"} />
                </div>
            </div>
        </div>
        <div className="event_open">
                <p className="event_h3">Event Information</p>
                <div className="event__body"><p>{eventId.event.body}</p></div>

        </div>

        <div className="event">
            <p>Additional Information</p>
        <div className=""><h5>Event Category: {eventId.event.category}</h5></div>
            <div className="event__body"><h3>{eventId.event.subHeader}</h3></div>
            <div className=" event__link">Link Name: {eventId.event.linkName}</div>
            <div className=" event__link">Link:<a href={ `${eventId.event.link}`}>{eventId.event.link}</a></div>
            <br/>
            <div>Start Date: {`${startDateFormat}`}</div>
            <div>End Date: {`${endDateFormat}`}</div>
            <br/>
            <div>Event Key: {eventId.id}</div>
            <br/>
            <div>Pinned: {`${eventId.event.pinned}`}</div>
            <br/>
            <div>
                <h5>Geopoint(s)</h5>
                {eventId.event.geopoints.map(geopoint => (
                    <>
                        <p>Latitude: {geopoint.latitude} | Longitude: {geopoint.longitude}</p>
                        <hr/>
                    </>
                ))}

            </div>
        </div>

        </div>        
    )
}
