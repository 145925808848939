import React from 'react';
import "./styles.css";

const Footer = () => {
    return(
        <>
        <p>Copyright 2021 Montana Repertory Theatre GoPlay!</p>
        <p>Programmed by Claire Spain</p>
        </>
    );
};

export default Footer;