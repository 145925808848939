import React, {useState, useEffect} from 'react';
import { db, storage, firebase} from '../../services/firebase';
import './styles.css';

export default function HeroForm() {

    const [backgroundImg, setBackgroundImg] = useState("");
    const [logo, setLogo] = useState("");
    const [buttonText, setButtonText] = useState('');
    const [buttonLink, setButtonLink] = useState('');
    const [carouselOrder, setCarouselOrder] = useState('');
    const [error, setError]= useState(false);


    const clearInputs = () => {
        setBackgroundImg('');
        setLogo('');
        setButtonText('');
        setButtonLink('');
        setCarouselOrder();

    };

    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     };
    
     const onBkImgChange = async (e) => {
         var imageName = makeid(10);
         const file = e.target.files[0]
         const fileref = storage.ref().child(`images/${imageName}`)
         await fileref .put(file)
         setBackgroundImg(await fileref.getDownloadURL())
     };
     const onLogoChange = async (e) => {
        var imageName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`images/${imageName}`)
        await fileref .put(file)
        setLogo(await fileref.getDownloadURL())
    };


    const checkCarouselId = async (e) => {
         setCarouselOrder(e.target.value);
        }
    
    const clearError = () => {
        setError('');
    }

    useEffect(() => { 
        clearError();
        var docRef = db.collection("carousel").where("carouselOrder", "==", carouselOrder);
        docRef.get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setError("Stop! This carousel already exists.");
            });
        })
        .catch((error) => {
            // console.log("Error getting documents: ", error);
        });
    },[carouselOrder])

     const handleUpload = (e) => {
         e.preventDefault();
         db.collection("carousel").add({
             backgroundImg: backgroundImg,
             logo: logo,
             buttonText: buttonText,
             buttonLink: buttonLink,
             carouselOrder: carouselOrder,
         });
         clearInputs();
     }

    return (
        <div>
            <div className="dash">
                <h3>🚧Under Construction🚧</h3>
                <h2>App Carousel on Homescreen</h2>
                <div>
                <label htmlFor="file-input" className="form-label">Background Image</label>
                    <input className="form-control" type="file" onChange={onBkImgChange} />
                </div>
                <br/>
                <div>
                <label htmlFor="file-input" className="form-label">Logo</label>
                    <input className="form-control" type="file" onChange={onLogoChange} />
                </div>
                <br/>
                <input className="form-control form-control-md" type="text" placeholder="Button Name"
                    value={buttonText}
                    onChange={(e) => setButtonText(e.target.value)}
                ></input>
                <input className="form-control form-control-md" type="text" placeholder="https://"
                    value={buttonLink}
                    onChange={(e) => setButtonLink(e.target.value)}
                ></input>
                <br/>
                <label htmlFor="file-input" className="form-label">(1 is the first banner seen)</label>
                <select value={carouselOrder} onChange={checkCarouselId} class="form-select w-25" aria-label="Default select example">
                    <option selected>Order</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                </select>
                <p className="errorMsg">{error}</p>
                <br/>
                <button type="submit" className="btn btn-primary mb-3" onClick={handleUpload}>Upload</button>
            </div>
        </div>
    )
}
