import React, {useState, useEffect} from 'react';
import { db } from '../../services/firebase';
import EventList from './EventList';
import {Link} from 'react-router-dom';
import "./styles.css"


export default function EventFeed({
    eventId, 
    setEventId,
    editEventId,
    setEditEventId}) {

        const [events, setEvents] = useState([]);
        

        useEffect(() => {
             db.collection("content").where("type", "==", "event").limit(25).orderBy("timestamp", "desc")
            .onSnapshot((snapshot) =>{
                setEvents(snapshot.docs.map((doc)=> ({id:doc.id, event: doc.data() })));
            })
               
        }, [])

  return (
    <div className="feed">
        <div>
        <div>
            {/* <div>List of events</div> */}
        </div>
        <div className="table table-borderless">
        <>
            <thead>
                <tr>
                    
                    {/* <th>Date</th> */}
                    <th className="event_title">Event Title</th>
                    <th className="event__overflow">Event Body</th>
                    <th>Photo Preview</th>
                    <th>Action</th>
                    
                </tr>
            </thead> 
            </>

            {events.map(({id, event, })=>{
                return ( <EventList
                key={id}
                id={id}
                eventTitle={event.title}
                photoUrl={event.photoUrl}
                eventBody={event.body}
                eventId={eventId}
                setEventId={setEventId}
                editEventId={editEventId}
                setEditEventId={setEditEventId} 
                setDbPublish={event.publish}
                // link={event.link}
                // timestamp={event.timestamp.Date}
                 />
               
            );
            })}
           
             </div>

    </div>
        </div>
  );
}
