import React, {useState, useEffect} from 'react';
import { db, storage } from '../../services/firebase';
import { Link } from 'react-router-dom';
import "./styles.css"

export default function SponsorList({
    id,
    sponsorTitle,
    sponsorBody,
    link, 
    linkName, 
    category, 
    subHeader, 
    mainPhotoUrl,
    timestamp,
    setDbPublish,
    sponsorId, 
    setSponsorId,
    editSponsorId,
    setEditSponsorId}) {

        //Actions
        const [publish, setPublish] = useState(setDbPublish); 

        const changePublish = () => {
            setPublish(!publish);
        }

        useEffect(()=> {
            db.collection("content").doc(id).update({
                publish: publish
            });
            // console.log(publish)
        })
    
        const previewsponsor = () => {
            db.collection("content").doc(id).onSnapshot((doc) =>{
                setSponsorId({id:doc.id, sponsor:doc.data()});
            });
        };

        const editsponsor = () => {
            db.collection("content").doc(id).onSnapshot((doc) => {
                setEditSponsorId({id:doc.id, sponsor: doc.data()});
            });
            
        };

        const deletesponsor = () => {
            if (window.confirm('Are you sure you wish to delete this item? This cannot be undone.')){
                
                try{
                if(mainPhotoUrl != ""){
                var imageRef = storage.refFromURL(mainPhotoUrl);
                try{imageRef.delete().then(function(){
                    console.log("delete successfully");
                }).catch(function(error){
                    console.log(`Error ${error}`);
                });
                }catch(error){
                    console.log(`Error ${error}`);
                } 
                }
                }catch(error){
                    console.log(`Error ${error}`);
                }

                db.collection("content").doc(id).delete().then(function(){
                    console.log("delete sponsor info successfully");
                }).catch(function(error){
                    console.log(`Error sponsor info ${error}`);
                });
            } else {
                return false;
            }
        };

  return (
      <>
    <tbody>
    <tr>
        {/* <td>{timestamp}</td> */}
        <td className="sponsor_title">{sponsorTitle}</td>
        <td className="sponsor__overflow">{sponsorBody}</td>
        <td><img style={{width: "100px", objectfit: "contain", borderRadius: "7%"}} alt="" src={mainPhotoUrl}></img></td>
        <td>
        <a className="btn text-success" title="Publish" defaultValue={setDbPublish}>
                            
                {publish ? (
                    <>
                        <span onClick={changePublish}><i className="fas fa-heart"></i></span>
                    </>
                ) : (
                    <>
                        <span onClick={changePublish}><i className="far fa-heart"></i></span>
                    </> 
                )
                }
                           
                </a>
            <Link to='/ShowSponsor'><a className="btn text-primary" title="Preview" onClick={previewsponsor}>
                <i  className="fas fa-eye"></i>
            </a></Link>
            <Link to='/EditSponsor'><a title="Edit" className="btn text-warning" onClick={editsponsor}>
                <i  className="fas fa-pencil-alt"></i>
            </a></Link>
            <a title="Delete" className="btn text-danger" onClick={deletesponsor} >
                <i  className="fas fa-trash-alt"></i>
            </a>
        </td>
    </tr>
</tbody>
</>
  );
}
