import React from 'react';
import "./styles.css"

export default function ShowPlay({playId}) {
    if(playId == null){
        return null;
    }

    let millisecondsStart;
    let  millisecondsEnd;
    let  startDate;
    let  endDate;
    let  startDateFormat;
    let  endDateFormat;
    let startFormat;


    try{
    millisecondsStart= playId.play.start.toDate()
    millisecondsEnd = playId.play.end.toDate()
    startDate = new Date(millisecondsStart)
    endDate = new Date(millisecondsEnd)
    startFormat = startDate.toLocaleDateString("en-US")
    startDateFormat = startDate.toLocaleString("en-US",  {timeZoneName: "short"})
    endDateFormat = endDate.toLocaleString("en-US", {timeZoneName: "short"})
    }catch{
        return null;
    }

    
    return (
        <div className="play">
        <div className="play__header">
            <div className="play__headerLeft"><h2>{playId.play.title}</h2></div>
            <div className="play__headerLeft"><h3>{playId.play.category}</h3></div>
            

        </div>
        <div >
            <img style={{
                width: "100%",
                objectfit: "contain",
            }} alt="" src={playId.play.mainPhotoUrl} />
        </div>
        <div className="play">
            <audio controls>
                <source src={playId.play.playUrl} type="audio/mp4" />
                Your browser does not support the audio tag.
            </audio>
            <br/>
            <br/>
            <video width="420" controls>
                <source src={playId.play.playUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
        <div>{playId.play.playUrl}</div>
        <div className="play__center">
            <div className="play__headerLeft"><h3>{playId.play.subHeader}</h3></div>
            <div className="play__body"><p>Body: {playId.play.body}</p></div>
            <div className="play__body"><p>Screenwriter: {playId.play.screenwriter}</p></div>
            <div className="play__body"><p>Copyright: {playId.play.copyrightDate}</p></div>
            <div className="play__body"><p>Location/Address: {playId.play.locationInfo}</p></div>
            <div className="play__body"><p>Actor Information: {playId.play.actorInfo}</p></div>
            <div className="play__body"><p>Additional Information: {playId.play.addInfo}</p></div>
            <div className="play__body"><p>Views: {playId.play.views}</p></div>
            <div className="play__body"><p>Type: {playId.play.type}</p></div>
            <div >
            <br/>
            <div>Start Date: {`${startDateFormat}`}</div>
            <div>End Date: {`${endDateFormat}`}</div>
            <br/>
            <div className="play__body"><p>Pinned: {`${playId.play.pinned}`}</p></div>
            <p>Schedule/Preview Photo:</p>    
            <img style={{
                width: "100%",
                objectfit: "contain",
            }} alt="" src={playId.play.previewPhotoUrl} />
            </div>
            <div>
                <h5>Geopoint(s)</h5>
                {playId.play.geopoints.map(geopoint => (
                    <>
                        <p>Latitude: {geopoint.latitude} | Longitude: {geopoint.longitude}</p>
                        <hr/>
                    </>
                ))}

            </div>
        </div>

    </div>
    )
}
