import React, {useState, useEffect} from 'react';
import { db, storage } from '../../services/firebase';
import { Link } from 'react-router-dom';
import "./styles.css"

export default function EventList({
    id,
    eventTitle,
    eventBody,
    link, 
    linkName, 
    category, 
    subHeader, 
    photoUrl,
    timestamp,
    setDbPublish,
    eventId, 
    setEventId,
    editEventId,
    setEditEventId}) {

        //Actions
        const [publish, setPublish] = useState(setDbPublish); 

        

        const changePublish = () => {
            
            setPublish(!publish); 
            
        }
        useEffect(() => {
            db.collection("content").doc(id).update({
                publish: publish
            });
            // console.log(publish)
        }, [publish])
    
        const previewEvent = () => {
            db.collection("content").doc(id).onSnapshot((doc) =>{
                setEventId({id:doc.id, event:doc.data()});
            });
        };

        const editEvent = () => {
            db.collection("content").doc(id).onSnapshot((doc) => {
                setEditEventId({id:doc.id, event: doc.data()});
            });
            
        };

        const deleteEvent = () => {
            if (window.confirm('Are you sure you wish to delete this item? This cannot be undone.')){
                
                try{
                if(photoUrl != ""){
                var imageRef = storage.refFromURL(photoUrl);
                try{imageRef.delete().then(function(){
                    console.log("delete successfully");
                }).catch(function(error){
                    console.log(`Error ${error}`);
                });
                }catch(error){
                    console.log(`Error ${error}`);
                } 
                }
                }catch(error){
                    console.log(`Error ${error}`);
                }

                db.collection("content").doc(id).delete().then(function(){
                    console.log("delete event info successfully");
                }).catch(function(error){
                    console.log(`Error event info ${error}`);
                });
            } else {
                return false;
            }
        };

  return (
      <>
      {/* <Link to='/ShowEvent' onClick={previewEvent}> */}
    <tbody>
    <tr>
        {/* <td>{timestamp}</td> */}
        <td className="event_title">{eventTitle}</td>
        <td className="event__overflow">{eventBody}</td>
        <td><img style={{width: "100px", objectfit: "contain", borderRadius: "7%"}} alt="" src={photoUrl}></img></td>
        <td>
        <a className="btn text-success" title="Publish" defaultValue={setDbPublish}>
                            
                {publish ? (
                    <>
                        <span onClick={changePublish}><i className="fas fa-heart"></i></span>
                    </>
                ) : (
                    <>
                        <span onClick={changePublish}><i className="far fa-heart"></i></span>
                    </> 
                )
                }
                           
                </a>
            <Link to='/ShowEvent'><a className="btn text-primary" title="Preview" onClick={previewEvent}>
                <i  className="fas fa-eye"></i>
            </a></Link>
            <Link to='/EditEvent'><a title="Edit" className="btn text-warning" onClick={editEvent}>
                <i  className="fas fa-pencil-alt"></i>
            </a></Link>
            <a title="Delete" className="btn text-danger" onClick={deleteEvent} >
                <i  className="fas fa-trash-alt"></i>
            </a>
        </td>
        
    
    </tr>
</tbody>
{/* </Link> */}
</>
  );
}
