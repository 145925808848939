import React, {useState} from 'react';
import CreateEvents from './CreateEvents';
import EditEvent from './EditEvent';
import EventFeed from './EventFeed';
import ShowEvent from './ShowEvent';
import {
  BrowserRouter as Router,
  Route, Link, Switch
} from 'react-router-dom';
import './styles.css';

const EventsPage = () => {

  const [eventId, setEventId] = useState();
  const [editEventId, setEditEventId] = useState();
      
    return(
        
        <div>
          <Router>
          <div className="page_title">
            <h1>Events</h1>
            <Link to='/CreateEvents'><button className="btn myButton">Add Event</button></Link>
            </div>

            <div className="section__half">
              <EventFeed
            eventId={eventId}
            setEventId={setEventId}
            editEventId={editEventId}
            setEditEventId={setEditEventId}
            /></div>

            <div className="section">
              <Switch>
                <Route path='/CreateEvents'>
                  <CreateEvents/>
                </Route>
                <Route path='/ShowEvent'>
                  <ShowEvent eventId={eventId} setEventId={setEventId}/>
                </Route>
                <Route path='/EditEvent'>
                  <EditEvent editEventId={editEventId} setEditEventId={setEditEventId}/>
                </Route>
              </Switch>
            </div>
            </Router>
        </div>
    );
};

export default EventsPage;