import React, {useState, useEffect} from 'react';
import {firebase} from '../../services/firebase';
import { db, storage } from '../../services/firebase';
import { Link } from 'react-router-dom';
import './styles.css';

export default function EditPost({id, playId, editPostId, setDbPin, setEditPostId, photoUrl}) {


    const [postTitle, setPostTitle] = useState("");
    const [postBody, setPostBody] = useState("");
    const [linkName, setLinkName] = useState("");
    const [link, setLink] = useState("");
    const [progress, setProgress] = useState(0);
    const [categoryValue, setCategoryValue] = useState("");
    const [commSubHeader, setCommSubHeader] = useState("");
    const [pin, setPin] = useState();
    const [keyId, setKeyId ] = useState('');
    const [fileUrl, setFileUrl] = useState("");
    const [error, setError]= useState(false);
    const [errorTip, setErrorTip] = useState(false);

    const [communitySpan, setCommunitySpan] = useState();

    const [tempFile, setTempFile] = useState("");
    const [randomKey, setRandomKey] = useState("");
    const [updateImg, setUpdateImg] = useState(false);

    const [isDisplay, setDisplay]=useState(true);

    
    const changePin = () => {
        editPostId.post.pinned=!editPostId.post.pinned;
        setPin(editPostId.post.pinned);
    }

    const updateTitle = (e) => {
        if(e.target.value==''){
            setPostTitle(' ');
        }
        else{
            setPostTitle(e.target.value)
        } 
    }

    const updateBody = (e) =>{
        if(e.target.value==''){
            setPostBody(' ');
        }
        else{
            setPostBody(e.target.value)
        }
    }

    const updateLinkName = (e) =>{
        if(e.target.value==''){
            setLinkName(' ')
        }
        else{
            setLinkName(e.target.value)
        }
    }

    const updateLink = (e) =>{
        if(e.target.value==''){
            setLink(' ')
        }
        else{
            setLink(e.target.value)
        }
    }

    const updateKeyId = (e) =>{
        if(e.target.value==''){
            setKeyId(' ')
        }
        else{
            setKeyId(e.target.value)
        }
    }

    const updateCommSubHeader = (e) =>{
        if(e.target.value==''){
            setCommSubHeader(' ')
        }
        else{
            setCommSubHeader(e.target.value)
        }
    }
    

    if(editPostId == null){
        return null;
    }

    const clearInputs = () => {
        setPostTitle('');
        setPostBody('');
        setLinkName('');
        setLink('');
        setCategoryValue('');
        setCommSubHeader('');
        setPin('');
        setFileUrl('');
        setProgress(0);
        setKeyId('');
    }

    const handleChangeBtn=()=>{
        setDisplay(false);
    }

    function scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     };

     const handleCategoryChange = (e) =>{
        if(e.target.name){
            setCategoryValue(e.target.value);
            console.log(e.target.value);
        }

        if(e.target.value==="community"){
            setCommunitySpan(true);
        }else{
            setCommunitySpan(false);
        }
        
    };

     const onFileUpdate =  async (e) => {   
        try{
        //cleanImage();
        }catch(error){
            console.log(error)
        }
        var imageName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`images/${imageName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
            var progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progress);
            setUpdateImg(true);
        },
        error => {
            console.log(error);
        },
    )
    setFileUrl(await fileref.getDownloadURL())
   
     setTempFile(imageName)
    console.log(tempFile);
        
    }

    //Removes original file to clean up db
    const getOldFileUrl = () => {
        var imageRef = storage.refFromURL(editPostId.post.photoUrl)
        console.log(imageRef)
        imageRef.delete().then(function(){
            console.log("delete success");
        }).catch(function(error){
            console.log(`Error ${error}`);
        });
        
    }

    const handleRemove = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`images/${tempFile}`)
            try {file.delete().then(function(){
                console.log("Main Image deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKey(randomString);
         setProgress(0);
         setUpdateImg(false);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }

    const handleUpdate = () => {
        if(postTitle !='' && categoryValue != '' ){
        if (window.confirm('Are you sure you wish to update this post?')){
            //if photoUrl/fileUrl has updated with new content
            //do getOldFileUrl();
            if(updateImg==true){
                getOldFileUrl();
            }else{
            }
            db.collection("posts").doc(editPostId.id).update({
               
                // timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                postTitle: postTitle || editPostId.post.postTitle,
                postBody: postBody || editPostId.post.postBody,
                photoUrl: fileUrl || editPostId.post.photoUrl,
                linkName: linkName || editPostId.post.linkName,
                link: link || editPostId.post.link,
                category: categoryValue || editPostId.post.category,
                subHeader: commSubHeader || editPostId.post.subHeader,
                key: keyId || editPostId.post.key,
                pinned: pin || editPostId.post.pinned,
            })
            .then(()=> {
                console.log("Document successfully updated!");
            });
        
        } else {
            return false;
        
        }
        clearInputs();
        var randomString = makeid(9)
        setRandomKey(randomString);
        setProgress(0);
        setUpdateImg(false);
        scrollToTop();
        }
        else {
            setError("You are missing one or more components.");
            setErrorTip("*");

        }
        
    };


    return (
        <div className="createPost">
            <div>
                <h2>Edit Post</h2>
                <div className="">
                    {/* <form> */}
                    <label className="errorMsg">{errorTip}</label>
                    <label >Title</label>
                    <input name="titleEdit" className="form-control form-control-lg" type="text" placeholder={`${editPostId.post.postTitle}`} aria-label=".form-control-lg example"
                        maxlength="50"
                        required
                        noValidate
                        value={postTitle || editPostId.post.postTitle} 
                        onChange={updateTitle}
                    ></input>
                    {/* <br></br> */}
                    <div className="mb-3">
                    <label>Body</label>
                    <textarea className="form-control" placeholder={`${editPostId.post.postBody}`} rows="3"
                        // value={postBody}
                        value={postBody || editPostId.post.postBody}
                        onChange={updateBody}
                    ></textarea>
                    </div>
                        {/* For testing purposes */}
                        {/* <img style={{width: "200px", objectfit: "contain", borderRadius: "7%"}} alt="" src={editPostId.post.photoUrl}></img> */}
                    
                    <div>
                   <input className="form-control" type="file" 
                   accept="images/*" 
                   key= {randomKey || ''}
                   onChange={onFileUpdate} />
                   </div>
                   <progress max="100" value={progress}></progress>
                   <br/>
                   <button type="submit" className="btn btn-danger mb-3" onClick={handleRemove}>Cancel</button>
                   <br/>
                   <label>Link Name</label>
                   <input className="form-control form-control-md" type="text"  placeholder={`${editPostId.post.linkName}`} aria-label=".form-control-lg example"
                        // value={linkName}
                        maxlength={50}
                        value={linkName || editPostId.post.linkName}
                        onChange={updateLinkName}
                        
                    ></input>
                    <label>Link Url</label>
                    <input className="form-control form-control-md" type="text" placeholder={`${editPostId.post.link}`} aria-label=".form-control-lg example"
                        value={link || editPostId.post.link}
                        
                        // value={link}
                        onChange={updateLink}
                    ></input>
                    <br></br>
                    <label>Key</label>
                    <input className="form-control form-control md" type="text" placeholder={`${editPostId.post.key}`}
                        value={keyId || editPostId.post.key}
                        onChange={updateKeyId}
                    >
                    </input>

                    {/* TODO: set default category value automatically */}
                    <br/>
                    <div defaultValue={editPostId.post.categoryValue} onChange={handleCategoryChange} required noValidate>
                    <label className="errorMsg">{errorTip}</label>
                    <label>Filter</label>
                        <div className="form-check"  >
                            <input className="form-check-input" type="radio" name="category" value="mtrep" />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Montana Repertory Theatre
                            </label>
                            </div>

                            <div className="form-check">
                            <input className="form-check-input" type="radio" name="category" value="goplay"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                GoPlay!
                            </label>
                            </div>
                            {/* <div className="form-check">
                            <input className="form-check-input" type="radio" name="category" value="friday"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                First Friday </label>
                            </div> */}
                            <div className="form-check">
                            <input className="form-check-input" type="radio" name="category" value="community" isChecked={handleChangeBtn}/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Community 
                            </label>
                            </div>
                        </div>
                    </div>          
                    {communitySpan == true && (
                         <span>
                        <label>Community SubHeader</label>
                        <input className="form-control form-control-md" type="text" aria-label=".form-control-lg example"
                         maxLength="50"
                         value={commSubHeader || editPostId.post.subHeader}
                         placeholder={`${editPostId.post.subHeader}`}
                         onChange={updateCommSubHeader}
                    ></input></span>
                    )}
                    <br></br>
                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" 
                    // value={pin} 
                    checked={editPostId.post.pinned}
                    onChange={changePin}
                     />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                        Pin this post to the top.
                    </label>
                    </div>
                    <br/>
                     
                    <div className="col-auto">
                        <label className="errorMsg">{error}</label>
                        <br/>
                        <button type="button" className="btn btn-warning mb-3" onClick={handleUpdate} >Update</button>              
                    </div>
                </div>
            </div>
    )
}

