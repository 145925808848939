import React, { useState, useEffect } from 'react';
import CreatePosts from './CreatePosts';
import PostFeed from './PostFeed';
import ShowPost from './ShowPost';
import EditPost from './EditPost';
import {
    BrowserRouter as Router,
    Route, Link, Switch
  } from 'react-router-dom';
import './styles.css';

const PostsPage = () => {
    const [postId, setPostId] = useState();
    const [editPostId, setEditPostId] = useState();

    return(
        <div>
        <Router>
        <div className="page_title">
        <h1>Posts</h1>
        <Link to="/CreatePosts"><button className="btn myButton">Add Post</button></Link>
        </div> 
        
        <div className="section__half">
        <PostFeed 
        postId={postId} 
        setPostId={setPostId}
        editPostId={editPostId}
        setEditPostId={setEditPostId}
        /></div>
        <div className="section">
            <Switch>
                <Route path='/CreatePosts'>
                    <CreatePosts/>
                </Route>
                <Route path='/ShowPost'>
                    <ShowPost postId={postId} setPostId={setPostId}/>
                </Route>
                <Route path='/EditPost'>
                    <EditPost editPostId={editPostId} setEditPostId={setEditPostId}/>
                </Route>
            </Switch>
            </div>   
        </Router>
        </div>
    );
};

export default PostsPage;