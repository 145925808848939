import React, {useState, useEffect} from 'react'
import { db, storage } from '../../services/firebase';
import { Link } from 'react-router-dom';
import "./styles.css";

export default function PlayList({
    id,
    playTitle,
    playBody,
    mainPhotoUrl,
    previewPhotoUrl,
    type,
    playUrl,
    playView,
    setDbPublish,
    TranscriptUrl,
    setPlayId,
    setEditPlayId,
    editPlayId
}) {

    const [publish, setPublish] = useState(setDbPublish); 

    const changePublish = () => { 
      setPublish(!publish);     
    }

    useEffect(() => {
        db.collection("content").doc(id).update({
            publish: publish
        });
        console.log(publish)
    }, [publish])


    const previewPlay = () => {
        db.collection("content").doc(id).onSnapshot((doc) =>{
            setPlayId({id:doc.id, play: doc.data()});
        });
        
    };

    const editPlay = () => {
        db.collection("content").doc(id).onSnapshot((doc) =>{
            setEditPlayId({id:doc.id, play:doc.data()});
        });
        
    };

    const deletePlay = () => {
        if (window.confirm('Are you sure you wish to delete this item? This cannot be undone.')){
            //Delete both images
           try{
            if(mainPhotoUrl != ""){
            var mainImageRef = storage.refFromURL(mainPhotoUrl);
            try {mainImageRef.delete().then(function(){
                console.log("Main Image deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }
            //Detele Preview Photo
            if(previewPhotoUrl != ""){
            var previewRef = storage.refFromURL(previewPhotoUrl);
            try {previewRef.delete().then(function(){
                console.log("Preview Image deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            }
            }
            //Delete play
            if(playUrl != ""){
            var playRef = storage.refFromURL(playUrl);
            try {playRef.delete().then(function(){
                console.log("Play deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            }
            }
            //Delete Transcript
            if(TranscriptUrl != ""){
            var transcriptRef = storage.refFromURL(TranscriptUrl);
            try {transcriptRef.delete().then(function(){
                console.log("Transcript deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            }
            }
           }catch(error){
               console.log(`Error ${error}`);
           }  
            //delete from firestore
            db.collection("content").doc(id).delete().then(function(){
                console.log("Delete all Play data successfully");
            }).catch(function(error){
                console.log(`Error play info ${error}`);
            });
    
        } else {
            return false;
        }
    };


    return (
        <>
         <tbody>
                <tr>
                    {/* <td>{timestamp}</td> */}
                    <td className="play_title">{playTitle}</td>
                    <td className="play_title">{type}</td>
                    <td className="play__overflow">{playBody}</td>
                    <td><img style={{width: "100px", objectfit: "contain", borderRadius: "7%"}} alt="" src={mainPhotoUrl}></img></td>
                    <td className="">{playView}</td>
                    <td className="play_action">
                        <a className="btn text-success" title="Publish" defaultValue={setDbPublish}>
                            
                            {publish ? (
                                <>
                                    <span onClick={changePublish}><i className="fas fa-heart"></i></span>
                                </>
                            ) : (
                                <>
                                   <span onClick={changePublish}><i className="far fa-heart"></i></span>
                                </> 
                            )
                            }
                           
                        </a>

                        <Link to="/ShowPlay"><a className="btn text-primary" title="Preview" onClick={previewPlay}>
                            <i  className="fas fa-eye"></i>
                        </a></Link>
                        <Link to="/EditPlay"><a title="Edit" className="btn text-warning" onClick={editPlay}>
                            <i  className="fas fa-pencil-alt"></i>
                        </a></Link>
                        <a title="Delete" className="btn text-danger" onClick={deletePlay} >
                            <i  className="fas fa-trash-alt"></i>
                        </a>
        
            
                    </td>
                </tr>
            </tbody>
        </>
  );
}
