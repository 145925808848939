import React, {useState, useEffect, useRef} from 'react';
import { db, auth} from '../../services/firebase';
import UserList from './UserList';
import "./styles.css"
// import * as admin from "firebase-admin";


export default function UserFeed({
  
    userId, 
    setUserId,
    editUserId,
    setEditUserId}) {

        const [users, setUsers] = useState([]);
        const [searchTerm, setSearchTerm] = useState("");
        const [searchResults, setSearchResults] = useState([]);
        const inputEl = useRef("");

        useEffect(() => {
            db.collection("users").orderBy("createdAt", "desc")
            .onSnapshot((snapshot)=>{
                setUsers(snapshot.docs.map((doc)=> ({id:doc.id, users: doc.data() })));
                // console.log(users);
            })
        }, [])

        // useEffect(() => {
        //     admin
        //         .auth
        //         .getUser(uid)
        //         .then((userRecord) => {
        //             console.log(`Successfully fetched user data: ${userRecord.toJSON()}`);
        //         })
        //         .catch((error) => {
        //           console.log('Error fetching user data:', error);
        //         });
        // }, [])

        const searchHandler = () => {
            
        };

        const getSearchTerm = () => {
            setSearchTerm(inputEl.current.value);
            if (searchTerm !== " ") {
                const newUserList = users.filter((user) => {
                  return Object.values(user)
                        .join(" ")
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase());
                });
                setSearchResults(newUserList);
                console.log("--------New User List-----");
                console.log(searchResults);
            } else {
                setSearchResults(users);
                console.log("----Users----");
                console.log(users);
            }
        };

  return (
    <div className="feed">
        <div>
        <div className="">
            <div className="input-group">
            <span className="input-group-text"><i className="fas fa-search"></i></span><input type="text" placeholder="Search User. . ." className="form-control w-50"
                ref={inputEl}
                value={searchTerm}
                onChange={getSearchTerm}
                disabled={true}
                // searchKeyword={searchHandler}
            />
            </div>
            <br/>
        </div>
        <div className="table table-borderless">
            <thead >
                <tr>
                    <th>Created At</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Admin</th>
                    <th>Premium</th>
                    {/* <th>Premium Start Date</th> */}
                    <th>Subscribed</th>
                    <th className="user_action">Actions</th>
                </tr>
            </thead>  

            {users.map(({id, users, })=>{
                return ( <UserList
                key={id}
                id={id}
                createdAt={users.createdAt.toDate()}
                email={users.email}
                isAdmin={users.isAdmin}
                isPremium={users.isPremium}
                isPremiumStartDate={users.isPremiumStartDate}
                isSubscribed={users.isSubscribed}
                username={users.name}
                userId={userId}
                setUserId={setUserId}
                editUserId={editUserId}
                setEditUserId={setEditUserId} 

                 />
               
            );
            })}
             </div>

    </div>
        </div>
  );
}
