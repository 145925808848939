import React from 'react'
import './styles.css';

export default function PaymentPage() {
    return (
        <div>
            <h1>Payment</h1>
            <h3>🚧Under Construction🚧</h3>
        </div>
    )
}
