import React, {useState} from 'react';
import CreateSponsors from './CreateSponsors';
import EditSponsor from './EditSponsor';
import SponsorFeed from './SponsorFeed';
import ShowSponsor from './ShowSponsor';
import {
  BrowserRouter as Router,
  Route, Link, Switch
} from 'react-router-dom';
import './styles.css';

const SponsoredPage = () => {

  const [sponsorId, setSponsorId] = useState();
  const [editSponsorId, setEditSponsorId] = useState();
      
    return(
        
        <div>
          <Router>
          <div className="page_title">
            <h1>Sponsors</h1>
            <Link to='/CreateSponsors'><button className="btn myButton">Add Content</button></Link>
            </div>
            <div className="section__half">
            <SponsorFeed
            sponsorId={sponsorId}
            setSponsorId={setSponsorId}
            editSponsorId={editSponsorId}
            setEditSponsorId={setEditSponsorId}
            /></div>

            <div className="section">
              <Switch>
                <Route path='/CreateSponsors'>
                  <CreateSponsors/>
                </Route>
                <Route path='/ShowSponsor'>
                  <ShowSponsor sponsorId={sponsorId} setSponsorId={setSponsorId}/>
                </Route>
                <Route path='/EditSponsor'>
                  <EditSponsor editSponsorId={editSponsorId} setEditSponsorId={setEditSponsorId}/>
                </Route>
              </Switch>
            </div>
            </Router>
        </div>
    );
};

export default SponsoredPage;