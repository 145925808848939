import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../services/routes.js';
import "./styles.css";
const Side = () => (
    <>

        <div>
            <Link to={ROUTES.DASHBOARD}><a><i className="fas fa-desktop"><span> Dashboard</span></i></a></Link>
        </div>

        <div>
        <Link to={ROUTES.PAYMENT}><a><i className="fas fa-dollar-sign"><span> Payment</span></i></a></Link>
        </div>

        <div>
        <Link to={ROUTES.USERS}><a><i className="fas fa-users"><span> Users</span></i></a></Link>
        </div>

        <div>
        <Link to={ROUTES.POSTS}><a><i className="fas fa-comment-alt"><span> Posts</span></i></a></Link>
        </div>

        <div>
        <Link to={ROUTES.PLAYS}><a><i className="fas fa-file-audio"><span> Plays</span></i></a></Link>
        </div>

        <div>
        <Link to={ROUTES.EVENTS}><a><i className="fas fa-calendar-day"><span> Events</span></i></a></Link>
        </div>

        <div>
        <Link to={ROUTES.SPONSORED}><a><i className="fas fa-city"><span> Sponsors</span></i></a></Link>
        </div>

       

    </>
);

export default Side;