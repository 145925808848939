import React, { useState, useEffect } from 'react'
// import { db, storage } from '../../services/firebase';
import "./styles.css";

export default function ShowPost({postId}) {

    if(postId == null){
        return null;
    }

    var pin = postId.post.pinned;
    // console.log(pin);
    var pinVal = pin.toString();

    return (
        <>
                <div className="post">
                    <div className="post__header">
                        <div className="post__headerLeft"><h2>{postId.post.postTitle}</h2></div>
                    </div>
                    <div className="post__headerLeft post_category"><h3 className="post_category">{postId.post.subHeader}</h3></div>
                    <div className="post__headerLeft post_category"><h3 className="post_category">{postId.post.category}</h3></div>
                    <div >
                        <img style={{
                            width: "100%",
                            objectfit: "contain",
                        }} alt="" src={postId.post.photoUrl} />
                    </div>
                    <div className="">
                        
                        <div className="post__body"><p>{postId.post.postBody}</p></div>
                        <div className="post__headerLeft post__link">{postId.post.linkName}</div>
                        
                    </div>
                    <br/>
                    <br/>
                </div>
                <div className="post">
                <p>Additional Information</p>
                <div className="post__headerLeft post__link"><a href={`${postId.post.link}`}>{postId.post.link}</a></div>
                <div className="post__headerLeft"><p>Key: {`${postId.post.key}`}</p></div>
                <div className="post__headerLeft"><p>Pinned: {`${pinVal}`}</p></div>
                </div>
                </>
    )
}
