import React, {useState} from 'react';
import DashAlert from './DashAlert';
import DashEvent from './DashEvent';
import DashFeed from './DashFeed';
import DashPost from './DashPost';
import DashPremium from './DashPremium';
import DashToDo from './DashToDo';
import HeroForm from './HeroForm';
import './styles.css';


export default function DashboardPage() {

    const [dashId, setDashId] = useState();

    return (
        <div className="page_title">
            <h1>Dashboard</h1>
            <h3>🚧Under Construction🚧</h3>
            <div>
                <h3>At a glance</h3>
                
            </div>
        <div className="parent">
            <div className= "div1">
            <DashFeed
            dashId={dashId}
            setDashId={setDashId}
            /></div>
        {/* <div className="div2">
            <DashPremium />
        </div> */}
       
         {/* <div className="div4">
             <DashPost />
         </div> */}
        
         {/* <div className="div5">
             <DashEvent />
         </div> */}
        
         {/* <div className="div3">
             <DashAlert />
         </div> */}
        
         <div className="div6">
             <HeroForm />
         </div>

         {/* <div className="div7">
            <DashToDo />
         </div> */}

         </div>
            
        </div>
    )
}
