import React, {useState} from 'react';
import { Button, Alert } from "react-bootstrap"
import { useAuth } from "../../services/AuthContext"
import logo from '../../assets/logo.png';
import { Link, useHistory } from "react-router-dom"

import "./styles.css";

const Header = () => {
    const [error, setError] = useState("")
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    async function handleLogout() {
        setError("")
    
        try {
          await logout()
          history.push("/login")
        } catch {
          setError("Failed to log out")
        }
      }
    return(
        <div>
        <div className="header">
          {error && <Alert variant="danger">{error}</Alert>}
          {/* <strong>Email:</strong> {currentUser.email} */}
          <img src={logo} alt="MRT Logo" />

            <div className="centerBtn">
            <Link to="/update-profile" ><a className="btn userLogo" title="Account">
              <i className="fas fa-user"></i>
            </a></Link>
            <a className="btn logout" title="Logout" onClick={handleLogout}>
              <i className="fas fa-sign-out-alt"></i>
            </a>
            </div>
      </div>
      <div className="redbar">
            </div>
      </div>
      
        
    );
};

export default Header;