import React, {useState} from 'react';
import CreatePlay from './CreatePlay';
import PlayFeed from './PlayFeed';
import ShowPlay from './ShowPlay';
import EditPlay from './EditPlay';
import {
    BrowserRouter as Router,
    Route, Link, Switch
  } from 'react-router-dom';
import './styles.css';

const PlaysPage = () => {

    const [playId, setPlayId] = useState();
    const [editPlayId, setEditPlayId] = useState();

    return(
        <div>
        <Router> 
        <div className="page_title">
        <h1>Play</h1>
        <Link to="/CreatePlay"><button className="btn myButton" >Add a Play</button></Link>
        </div>
        <div className="section__half"><PlayFeed
            playId={playId}
            setPlayId={setPlayId}
            editPlayId={editPlayId}
            setEditPlayId={setEditPlayId}
            /></div>
        <div className="section">
               <Switch>
                <Route path='/CreatePlay'>
                    <CreatePlay/>
                </Route>

                <Route path='/ShowPlay'>
                    <ShowPlay playId={playId} setPlayId={setPlayId}/>
                </Route>
                <Route path='/EditPlay'>
                    <EditPlay  editPlayId={editPlayId} setEditPlayId={setEditPlayId}/>
                </Route>
               </Switch>               
            </div>
            </Router>
            </div>
    );
};

export default PlaysPage;