import React, {useState} from 'react';
import { db, storage } from '../../services/firebase';
import { Link } from 'react-router-dom';
import "./styles.css"

export default function dashList({
    id,
    dashTitle,
    dashBody,
    photoUrl,
    playView,}) {

        

  return (
      <>
    <tbody>
    <tr>
        {/* <td>{timestamp}</td> */}
        <td className="dash_title">{dashTitle}</td>
        <td className="dash__overflow">{dashBody}</td>
        <td><img style={{width: "100px", objectfit: "contain", borderRadius: "7%"}} alt="" src={photoUrl}></img></td>
        <td className="">{playView}</td>
    </tr>
</tbody>
</>
  );
}
