import React, {useState} from 'react';
import { db } from '../../services/firebase';
import { Link } from 'react-router-dom';
import "./styles.css"

export default function UserList({
    id,
    createdAt, 
    email,
    isAdmin, 
    isPremium, 
    isPremiumStartDate,
    isSubscribed,
    username,
    userId, 
    setUserId,
    editUserId,
    setEditUserId}) {

        //Actions
        const previewUser = () => {
            db.collection("users").doc(id).onSnapshot((doc) =>{
                setUserId({id:doc.id, user:doc.data()});
            });
        };

        const editUser = () => {
            db.collection("users").doc(id).onSnapshot((doc) => {
                setEditUserId({id:doc.id, User: doc.data()});
            });
            console.log(editUserId)
        };

        const deleteUser = () => {
            if (window.confirm('Are you sure you wish to delete this item? This cannot be undone.')){
                // db.collection("users").doc(id)(FirebaseAuth.getInstance().currentUser.uid).delete()
                db.collection("users").doc(id).delete().then(function(){
                    console.log("delete User info successfully");
                }).catch(function(error){
                    console.log(`Error User info ${error}`);
                });
            } else {
                return false;
            }
        };

  return (
      <>
    <tbody>
    <tr>
        <td >{`${createdAt.toLocaleDateString('en-US')}`}</td>
        <td >{username}</td>
        <td >{email}</td>
        <td >{`${isAdmin}`}</td>
        <td >{`${isPremium}`}</td>
        {/* <td >{isPremiumStartDate}</td> */}
        <td >{`${isSubscribed}`}</td>
        <td>
            {/* <a className="btn text-primary" title="Preview" onClick={previewUser}>
                <i  className="fas fa-eye"></i>
            </a> */}
            <Link to='/EditUser'>
           <a title="Edit" className="btn text-warning" onClick={editUser}>
                <i  className="fas fa-pencil-alt"></i>
            </a></Link>

            <a title="Delete" className="btn text-danger" onClick={deleteUser} >
                <i  className="fas fa-trash-alt"></i>
            </a>
        </td>
    </tr>
</tbody>
</>

  );
}
