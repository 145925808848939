import React, {useState} from 'react';
import { db, storage, firebase } from '../../services/firebase';
import DateTimePicker from 'react-datetime-picker';
import "./styles.css";

const CreatePlay = () => {

    const [playTitle, setPlayTitle] = useState("");
    const [playSubHeader, setPlaySubHeader] = useState("");
    const [screenwrite, setScreenwrite] = useState("");
    const [copyright, setCopyright] = useState("");
    const [actorInfo, setActorInfo] = useState("");
    const [addInfo, setAddInfo] = useState("");
    const [playBody, setPlayBody] = useState("");
    const [locationInfo, setLocationInfo] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [preImgUrl, setPreImgUrl] = useState("");
    const [playUrl, setPlayUrl] = useState("")
    const [ccUrl, setCCUrl] = useState("");
    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [typeVar, setTypeVar] = useState(true);
    const [type, setType] = useState('play');
    const [pin, setPin] = useState(false);
    const [geopoints, setGeopoints] = useState([
        {latitude: '', longitude: '', id: 0},
    ]);

    const [progress, setProgress] = useState(0);
    const [progressPre, setProgressPre] = useState(0);
    const [progressPlay, setProgressPlay] = useState(0);
    const [progressCC, setProgressCC] = useState(0);

    const [randomKey, setRandomKey] = useState("");
    const [randomKeyPre, setRandomKeyPre] = useState("");
    const [randomKeyPlay, setRandomKeyPlay] = useState("");
    const [randomKeyCC, setRandomKeyCC] = useState("");

    const [newImageName, setNewImageName] = useState("");
    const [newPreName, setNewPreName] = useState("");
    const [newPlayName, setNewPlayName] = useState("");
    const [newCCName, setNewCCName] = useState("");


    const [error, setError]= useState(false);
    const [errorTip, setErrorTip] = useState(false);

   
    const clearInputs = () => {
        setPlayTitle('');
        setPlaySubHeader('');
        setScreenwrite('');
        setCopyright('');
        setActorInfo('');
        setAddInfo('');
        setPlayBody('');
        setImgUrl('');
        setPreImgUrl('');
        setPlayUrl('');
        setCCUrl('');
        setStart('');
        setEnd('');
        setLocationInfo('');
        setPin('');
        setProgress(0);
        setProgressPre(0);
        setProgressPlay(0);
        setProgressCC(0);
        setGeopoints([{ latitude: '', longitude: '', id: ''},]);
        setError(false);
        setErrorTip(false);

    }

    let geoCounter = 0;

    const handleChangeInput = (id, e) => {
        
        const values = [...geopoints];
        values[id][e.target.name] = e.target.value;
        setGeopoints(values);
    }

    const handleAddFields = () => {
        setGeopoints([...geopoints, {latitude: '', longitude: '' , id: geoCounter++}])
    }

    const handleRemoveFields = (id) => {
        if( id > 0){
        const values = [...geopoints];
        values.splice(id,  1);
        setGeopoints(values);
        }else {
            return false;
        };
    };


    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     };



    const handleImgUpload = async (e) => {
        var imageName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`images/${imageName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
                
                
            },
            error => {
                console.log(error);
            },
        )
        setImgUrl(await fileref.getDownloadURL())  
        // console.log(fileref); 
        setNewImageName(imageName);
        // console.log(newImageName);
    }

    const handleRemoveMain = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`images/${newImageName}`)
            try {file.delete().then(function(){
                console.log("Main Image deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKey(randomString);
         setProgress(0);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }


    const handlePreImgUpload = async (e) => {
        var imagePreName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`images/${imagePreName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progressPre = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgressPre(progressPre);
            },
            error => {
                console.log(error);
            },
        )
        setPreImgUrl(await fileref.getDownloadURL())
        setNewPreName(imagePreName);
    }

    const handleRemovePreImgUpload = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`images/${newPreName}`)
            try {file.delete().then(function(){
                console.log("Preview Image deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKeyPre(randomString);
         setProgressPre(0);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }

    const handlePlayUpload = async (e) => {
        var playName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`playContent/${playName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progressPlay = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgressPlay(progressPlay);
            },
            error => {
                console.log(error);
            },
        )
        setPlayUrl(await fileref.getDownloadURL())
        setNewPlayName(playName);
    }

    const handleRemovePlayUpload = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`playContent/${newPlayName}`)
            try {file.delete().then(function(){
                console.log("Play deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKeyPlay(randomString);
         setProgressPlay(0);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }


    const handleTranscriptUpload = async (e) => {
        var transcriptName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`transcripts/${transcriptName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progressCC = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgressCC(progressCC);
            },
            error => {
                console.log(error);
            },
        )
        setCCUrl(await fileref.getDownloadURL())
        setNewCCName(transcriptName);

    }

    const handleRemoveTranscriptUpload = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`transcripts/${newCCName}`)
            try {file.delete().then(function(){
                console.log("Transcript deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKeyCC(randomString);
         setProgressCC(0);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }


    // var type = 'play'
    const toggle = async (e) => {
        setTypeVar(!typeVar)
        
        //  console.log(e.target.value)
       if (typeVar == true){
            setType('easterEgg')
       } else {
           setType('play')
       }
    }
    console.log(pin);

    function scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

   

    const handleUpload = (e) => {
    if(playTitle != '' && start != '' && end != '' && imgUrl != '' && playUrl != ''  && geopoints != ''){
        db.collection("content").add({
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            title: playTitle,
            subHeader: playSubHeader,
            screenwriter: screenwrite,
            copyrightDate: copyright,
            actorInfo: actorInfo,
            addInfo: addInfo,
            locationInfo: locationInfo,
            category: "goplay",
            body: playBody,
            mainPhotoUrl: imgUrl,
            previewPhotoUrl: preImgUrl,
            playUrl: playUrl,
            TranscriptUrl: ccUrl,
            start: start,
            end: end,
            publish: false,
            views: 0,
            type: type,
            pinned: pin,
            geopoints: firebase.firestore.FieldValue.arrayUnion(...geopoints),

        });
        clearInputs();
        var randomString = makeid(9)

        setRandomKey(randomString);
        setRandomKeyPre(randomString);
        setRandomKeyPlay(randomString);
        setRandomKeyCC(randomString);
        scrollToTop();

        }else {
            setError("You are missing one or more components.");
            setErrorTip("*");
        }
    };

    return (
        <div className="createPlay play">
            <div>
                    <h2>Create a Play for Premium Members</h2>
                <div>
                    <label className="errorMsg">{errorTip}</label>
                    <input className="form-control form-control-lg" type="text" placeholder="Play Title" aria-label=".form-control-lg example"
                        value={playTitle}
                        onChange={(e) => setPlayTitle(e.target.value)}
                    ></input>
                    <br></br>
                    <input className="form-control form-control-md" type="text" placeholder="Optional: Title SubHeader" aria-label=".form-control-lg example"
                        value={playSubHeader}
                        onChange={(e) => setPlaySubHeader(e.target.value)}
                    ></input>
                    <br></br>
                    <input className="form-control form-control-md" type="text" placeholder="Screenwriter/ Author" aria-label=".form-control-lg example"
                        value={screenwrite}
                        onChange={(e) => setScreenwrite(e.target.value)}
                    ></input>
                    <br></br>
                    <input className="form-control form-control-md" type="number" placeholder="Copyright Date" aria-label=".form-control-lg example"
                        value={copyright}
                        onChange={(e) => setCopyright(e.target.value)}
                    ></input>
                    <br></br>
                    <div className="mb-3">
                    <textarea className="form-control" placeholder="Body" rows="4"
                        value={playBody}
                        onChange={(e) => setPlayBody(e.target.value)}
                    ></textarea>
                    <br/>
                    <div className="mb-3">
                    <textarea className="form-control" placeholder="Information about Geopoint/Address" rows="2"
                        value={locationInfo}
                        onChange={(e) => setLocationInfo(e.target.value)}
                    ></textarea>
                    </div>
                    <div>
                    <label className="errorMsg">{errorTip}</label>
                    <label>Start Date & Time</label>
                    <br></br>
                    <DateTimePicker className="btn-space form-control-md" onChange={setStart} value={start} />
                    <br></br>
                </div>
                <br/>
                <div>
                    <label className="errorMsg">{errorTip}</label>
                    <label>Event End Date & Time</label>
                    <br></br>
                    <DateTimePicker className="btn-space form-control-md" onChange={setEnd} value={end} />
                </div>
                    </div>
                    <div className="mb-3">
                    <textarea className="form-control" placeholder="Actor Information" rows="2"
                        value={actorInfo}
                        onChange={(e) => setActorInfo(e.target.value)}
                    ></textarea>
                    </div>
                   
                    <div className="mb-3">
                    <textarea className="form-control" placeholder="Additional Information" rows="2"
                        value={addInfo}
                        onChange={(e) => setAddInfo(e.target.value)}
                    ></textarea>
                    </div>
                    <div className="mb-3">
                    <label className="errorMsg">{errorTip}</label>
                    <label htmlFor="file-input" className="form-label">Main Image</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        accept="image/*"
                        key= {randomKey || ''}
                        onChange={handleImgUpload}
                    />
                    <progress max="100" value={progress}></progress>
                    <br/>
                   <button type="submit" className="btn btn-danger mb-3" onClick={handleRemoveMain}>Cancel</button>
                   <br/>
                    </div>
                    <div className="mb-3">
                    <label htmlFor="file-input" className="form-label">Preview Image</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        accept="image/*"
                        key= {randomKeyPre || ''}
                        onChange={handlePreImgUpload}
                    />
                    <progress max="100" value={progressPre}></progress>
                    <br/>
                    <button type="submit" className="btn btn-danger mb-3" onClick={handleRemovePreImgUpload}>Cancel</button>
                    </div>
                    <div className="mb-3">
                    <label className="errorMsg">{errorTip}</label>
                    <label htmlFor="file-input" className="form-label">Choose Video or Audio to upload</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        accept="video/*, audio/*"
                        key= {randomKeyPlay || ''}
                        onChange={handlePlayUpload}
                    />
                    <p>This one may take awhile. . .</p>
                    <progress max="100" value={progressPlay}></progress>
                    <br/>
                   <button type="submit" className="btn btn-danger mb-3" onClick={handleRemovePlayUpload}>Cancel</button>
                   <br/>
                    </div>
                    <div className="mb-3">
                    {/* <label className="errorMsg">{errorTip}</label> */}
                    <label htmlFor="file-input" className="form-label">Choose Transcript file to upload</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        key={randomKeyCC || ''}
                        onChange={handleTranscriptUpload}
                    />
                    <progress max="100" value={progressCC}></progress>
                    <br/>
                   <button type="submit" className="btn btn-danger mb-3" onClick={handleRemoveTranscriptUpload}>Cancel</button>
                   </div>
                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" 
                    value={pin} 
                    onChange={(e) => setPin(!pin)}
                     />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                        Pin this play to the top.
                    </label>
                    </div>
                    <br/>
                    <br/>

                    <div >
                    <p className="typeSet">Play</p>
                    <label class="switch typeSet">
                    <input type="checkbox" 
                        value={typeVar}
                        onChange={toggle}
                    />
                    <span class="slider round"></span>
                    </label>
                    <p className="typeSet">Easter Egg</p>
                    </div>
                    <br/>
                    

                    <h6>Geopoints</h6>
                    <label className="errorMsg">{errorTip}</label>  
                    { geopoints.map((geopoint, id)=> (
                    <div className="plays__geopoints_div" key={id}>
                        <input 
                        className="form-control form-control-md plays__geopoints"
                        name="latitude" 
                        label="Latitude" 
                        value={geopoint.latitude} 
                        type="number"
                        onChange={(e) => handleChangeInput(id, e)}
                        
                        />
                        <input  
                        className="form-control form-control-md plays__geopoints"
                        name="longitude" 
                        label="Longitude" 
                        value={geopoint.longitude} 
                        type="number"
                        onChange={(e) => handleChangeInput(id, e)}
                        
                        />
                        
                        <a title="Remove" className="btn btn-danger btn-space" onClick={()=> handleRemoveFields(id)}>
                            <i className="fas fa-minus"></i>
                        </a>
                        <a title="Add" className="btn btn-success btn-space" onClick={()=> handleAddFields()}>
                            <i className="fas fa-plus"></i>
                        </a>
                        
                    </div>
                )) }
                <br></br>
                <label className="errorMsg">{error}</label>
                    <div className="col-auto">
                        <button type="button" className="btn btn-primary mb-3" onClick={handleUpload} >Upload</button>
                    </div>
                </div>
            </div>
        </div>
    // </div>
    );
};

export default CreatePlay;
