import React, {useState, useEffect} from 'react';
import { db, storage, firebase } from '../../services/firebase';
import DateTimePicker from 'react-datetime-picker';
import "./styles.css";


export default function CreateEvents() {

    const [eventTitle, setEventTitle] = useState("");
    const [eventBody, setEventBody] = useState("");
    const [linkName, setLinkName] = useState("");
    const [link, setLink] = useState("");
    const [locationInfo, setLocationInfo] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [categoryValue, setCategoryValue] = useState("");
    const [commSubHeader, setCommSubHeader] = useState("");
    const [pin, setPin] = useState(false);
    const [geopoints, setGeopoints] = useState([
        { latitude: '', longitude: '', id: 0},
    ]);
    const [progress, setProgress] = useState(0);
    // const [updateImg, setUpdateImg] = useState(false);

    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());

    const [tempFile, setTempFile] = useState("");
    const [randomKey, setRandomKey] = useState("");
    const [error, setError]= useState(false);
    const [errorTip, setErrorTip] = useState(false);

    const [communitySpan, setCommunitySpan] = useState();

    let geoCounter = 0;

    const clearInputs = () => {
        setEventTitle('');
        setEventBody('');
        setLinkName('');
        setLink('');
        setLocationInfo('');
        setFileUrl('');
        setCategoryValue('');
        setCommSubHeader('');
        setStart('');
        setEnd('');
        setPin('');
        setGeopoints([{ latitude: '', longitude: '', id: ''},]);
        setError(false);
        setErrorTip(false);
        
    }

    

    const handleChangeInput = (id, e) => {
        
        const values = [...geopoints];
        if(e.target.value==null){
            return null;
        }
        values[id][e.target.name] = e.target.value;
        setGeopoints(values);
    }

    const handleAddFields = () => {
        setGeopoints([...geopoints, {latitude: '', longitude: '' , id: geoCounter++}])
    }

    const handleRemoveFields = (id) => {
        if( id > 0){
        const values = [...geopoints];
        values.splice(id,  1);
        setGeopoints(values);
        }else {
            return false;
        };
    };

    const handleCategoryChange = (e) =>{
    
        if(e.target.name){
            setCategoryValue(e.target.value);
            console.log(e.target.value);
        }
        if(e.target.value==="community"){
            setCommunitySpan(true);
        }else{
            setCommunitySpan(false);
        }
        
    };
    
    function isValidHttpUrl(string) {
        let url;
        
        try {
            url = new URL(string);
        } catch (_) {
            return false;  
        }
        
        return url.protocol === "http:" || url.protocol === "https:";
    }
    
    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     };
    
     const onFileChange = async (e) => {
         var imageName = makeid(10);
         const file = e.target.files[0]
         const fileref = storage.ref().child(`images/${imageName}`)
         await fileref .put(file)
         let uploadTask = fileref.put(file);
         uploadTask.on("state_changed", function(snapshot){
                 var progress = Math.round(
                     (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                 );
                 setProgress(progress);
             },
             error => {
                 console.log(error);
             },
         )
         setFileUrl(await fileref.getDownloadURL())
         setTempFile(imageName)
     }

     
    const handleRemove = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`images/${tempFile}`)
            try {file.delete().then(function(){
                console.log("Main Image deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKey(randomString);
         setProgress(0);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }

    function scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

      console.log(pin)

     const handleUpload = (e) => {

        if(eventTitle != '' && start != '' && end != '' && categoryValue != '' && fileUrl != ''){
         db.collection("content").add({
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            title: eventTitle,
            body: eventBody,
            photoUrl: fileUrl, 
            linkName: linkName,
            link: link,
            locationInfo: locationInfo,
            category: categoryValue,
            subHeader: commSubHeader,
            start: start,
            end: end,
            geopoints: firebase.firestore.FieldValue.arrayUnion(...geopoints),
            publish: true,
            pinned: pin,
            type: "event"
           
         });
         clearInputs();
         var randomString = makeid(9)
         setRandomKey(randomString);
         setProgress(0);
        //  setUpdateImg(false);
        scrollToTop();
        }else{
            setError("You are missing one or more components.");
            setErrorTip("*");
        }
     };

   
    return (
        <div className="CreateEvent event">
           <div>
               <h2>Create an Event</h2>
               <div>
               <label className="errorMsg">{errorTip}</label>
               <input className="form-control form-control-lg" type="text" placeholder="Event Title" aria-label=".form-control-lg example"
                        value={eventTitle}
                        maxLength="50"
                        onChange={(e) => setEventTitle(e.target.value)}
                    ></input>
                <br></br>
                <div className="mb-3">
                    <textarea className="form-control" placeholder="Body" rows="3"
                        value={eventBody}
                        onChange={(e) => setEventBody(e.target.value)}
                    ></textarea>
                    </div>
                <div className="mb-3">
                    <textarea className="form-control" placeholder="Information about Geopoint/Address" rows="2"
                        value={locationInfo}
                        onChange={(e) => setLocationInfo(e.target.value)}
                    ></textarea>
                    </div>
                <div>
                <label className="errorMsg">{errorTip}</label>
                <div>
                   <input className="form-control" 
                   type="file"
                    accept="images/*" 
                    key= {randomKey || ''}
                    onChange={onFileChange} />
                   </div>
                   <progress max="100" value={progress}></progress>
                   <br/>
                   <button type="submit" className="btn btn-danger mb-3" onClick={handleRemove}>Cancel</button>
                   <br/>
                </div>
                <br></br>
                <div>
                    <input className="form-control form-control-md" type="text" placeholder="Link Name" aria-label=".form-control-lg example"
                        value={linkName}
                        maxLength="50"
                        onChange={(e) => setLinkName(e.target.value)}
                    ></input>
                    
                    <input className="form-control form-control-md" type="url" pattern="https?://.+" required placeholder="https://example.com" aria-label=".form-control-lg example"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    ></input>
                    </div>
                    <br></br>
                <div>
                <label className="errorMsg">{errorTip}</label>
                    <label>Event Start Date & Time</label>
                    
                    <br/>
                    <DateTimePicker className="btn-space form-control-md" onChange={setStart} value={start} required />
                    <br></br>
                </div>
                <br/>
                <div>
                <label className="errorMsg">{errorTip}</label>
                    <label>Event End Date & Time</label>
                    <br></br>
                    <DateTimePicker className="btn-space form-control-md" onChange={setEnd} value={end} required />
                </div>
                <br></br>
                {/* Category Section */}
                <div onChange={handleCategoryChange}>
                <label className="errorMsg">{errorTip}</label>
                        <div className="form-check">
                        <input className="form-check-input" type="radio" name="category" value="mtrep" />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Montana Repertory Theatre
                            </label>
                        </div>
                        {/* <div className="form-check">
                        <input className="form-check-input" type="radio" name="category" value="friday"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                First Friday </label>
                            </div> */}
                        <div className="form-check">
                        <input className="form-check-input" type="radio" name="category" value="community"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Community </label>
                            </div>
                    </div>
                    {communitySpan == true && (
                         <span>
                        {/* <label>Community SubHeader</label> */}
                        <input className="form-control form-control-md" type="text" aria-label=".form-control-lg example"
                         maxLength="50"
                         value={commSubHeader}
                         placeholder="Optional: Community Subheader"
                         onChange={(e) => setCommSubHeader(e.target.value)}
                    ></input></span>
                    )}
                    <br/>
                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" 
                    value={pin} 
                    onChange={(e) => setPin(!pin)}
                     />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                        Pin this post to the top.
                    </label>
                    </div>

                    <br></br>
                
                <h6>Geopoints</h6>
                { geopoints.map((geopoint, id)=> (
                    <div className="events__geopoints_div" key={id}>
                        <input 
                        className="form-control form-control-md events__geopoints w-50"
                        name="latitude" 
                        label="Latitude" 
                        value={geopoint.latitude} 
                        type="number"
                        onChange={(e) => handleChangeInput(id, e)}
                        
                        />
                        <input  
                        className="form-control form-control-md events__geopoints w-50"
                        name="longitude" 
                        label="Longitude" 
                        value={geopoint.longitude} 
                        type="number"
                        onChange={(e) => handleChangeInput(id, e)}
                        
                        />
                        
                        <a title="Remove" className="btn btn-danger btn-space" onClick={()=> handleRemoveFields(id)}>
                            <i className="fas fa-minus"></i>
                        </a>
                        <a title="Add" className="btn btn-success btn-space" onClick={()=> handleAddFields()}>
                            <i className="fas fa-plus"></i>
                        </a>
                        
                    </div>
                )) }
                
                <br></br>
                <label className="errorMsg">{error}</label>
                        <br/>
                        <br/>
                    <div className="col-auto">
                        <button type="button" className="btn btn-primary mb-3" onClick={handleUpload}>Upload</button>
                    </div>
               </div>
           </div>
        </div>
    )
}
