import React, {useEffect, useState} from 'react';
import { db, storage, firebase } from '../../services/firebase';
import { Link } from 'react-router-dom';
import "./styles.css";

const CreatePosts = () => {

    const [postTitle, setPostTitle] = useState("");
    const [postBody, setPostBody] = useState("");
    const [linkName, setLinkName] = useState("");
    const [link, setLink] = useState("");
    const [progress, setProgress] = useState(0);
    const [categoryValue, setCategoryValue] = useState("");
    const [commSubHeader, setCommSubHeader] = useState("");
    const [pin, setPin] = useState(false);
    const [fileUrl, setFileUrl] = useState("");
    const [keyId, setKeyId ] = useState("");
    const [error, setError]= useState(false);
    const [errorTip, setErrorTip] = useState(false);
    const [communitySpan, setCommunitySpan] = useState();

    
    const [tempFile, setTempFile] = useState("");
    const [randomKey, setRandomKey] = useState("");
    const [updateImg, setUpdateImg] = useState(false);
    const [disabler,setDisabler] = useState(true);
    const [showInput, setShowInput] = useState(false);
    const [value, setValue] = useState(false);

    const clearInputs = () => {
        setPostTitle('');
        setPostBody('');
        setLinkName('');
        setLink('');
        setCategoryValue('');
        setCommSubHeader('');
        setPin('');
        setFileUrl('');
        setError(false);
        setErrorTip(false);
        setKeyId('');
    }
    



    const handleChange = () => {
               setValue(true);
               console.log(value);
               handleValueChange();
    };

    function handleValueChange(){
        if( setValue === true){
            setShowInput(true)
        }else{
            setShowInput(false)
        }
    }

    const handleCategoryChange = (e) =>{
        if(e.target.name){
            setCategoryValue(e.target.value);
            console.log(e.target.value);
        }
        if(e.target.value==="community"){
            setCommunitySpan(true);
        }else{
            setCommunitySpan(false);
        }
        
    };
    
    function scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     };


    const onFileChange =  async (e) => {
        var imageName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`images/${imageName}`)
        await fileref .put(file);
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
            },
            error => {
                console.log(error);
            },
        )
        setFileUrl(await fileref.getDownloadURL())  
        setTempFile(imageName)
    }

    const handleRemove = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`images/${tempFile}`)
            try {file.delete().then(function(){
                console.log("Main Image deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKey(randomString);
         setProgress(0);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }



    const handleUpload = (e) => {
        // e.preventDefault();
        if(postTitle !='' && categoryValue != '' ){
                    db.collection("posts").add({
                        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                        postTitle: postTitle,
                        postBody: postBody,
                        photoUrl: fileUrl,
                        linkName: linkName,
                        link: link,
                        category: categoryValue,
                        subHeader: commSubHeader,
                        pinned: pin,
                        key: keyId,
                        publish: true,
                    });
                    clearInputs();
                    var randomString = makeid(9)
                    setRandomKey(randomString);
                    setProgress(0);
                    setUpdateImg(false);
                    scrollToTop();
                   
                }
                else {
                    setError("You are missing one or more components.");
                    setErrorTip("*");

                }
                

    };

    console.log(pin)
    


    return (
        <div className="createPost post">
            <div>
                <h2>Create Post</h2>
                <div className="">
                   <form>
                   <label className="errorMsg">{errorTip}</label>
                    <input className="form-control form-control-lg" type="text" placeholder="Post Title" aria-label=".form-control-lg example"
                        maxlength="50"
                        required
                        noValidate
                        value={postTitle}
                        onChange={(e) => setPostTitle(e.target.value)}
                    ></input>
                    <br></br>
                    <div className="mb-3">
                    <textarea className="form-control" placeholder="Body" rows="3"
                        value={postBody}
                        onChange={(e) => setPostBody(e.target.value)}
                    ></textarea>
                    </div>
                    <div>
                   <input className="form-control" 
                   type="file"
                    accept="images/*" 
                    key= {randomKey || ''}
                    onChange={onFileChange} />
                   </div>

                   <progress max="100" value={progress}></progress>
                   <br/>
                   <button type="submit" className="btn btn-danger mb-3" onClick={handleRemove}>Cancel</button>
                   <br/>

                    {/* Link Section */}
                    {/* //TODO: If one or the other has data the other needs data...? */}
                    <div>
                    <input className="form-control form-control-md" type="text" placeholder="Link Name" aria-label=".form-control-lg example"
                        maxlength="50"
                        value={linkName}
                        onChange={(e) => setLinkName(e.target.value)}
                    ></input>
                    
                    <input className="form-control form-control-md" type="url" pattern="https?://.+" placeholder="https://example.com" aria-label=".form-control-lg example"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    ></input>
                    </div>
                    <input className="form-control form-control md" type="text" placeholder="Key from Event/Sponsor/Play/etc"
                        value={keyId}
                        onChange={(e) => setKeyId(e.target.value)}
                    >
                    </input>
                    <br/>
                    {/* Category Section */}
                    <div onChange={handleCategoryChange}  required noValidate>
                    <label className="errorMsg">{errorTip}</label>
                        <div className="form-check">
                        <input className="form-check-input" type="radio" name="category" value="mtrep" />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Montana Repertory Theatre
                            </label>
                        </div>
                        <div className="form-check">
                        <input className="form-check-input"  type="radio" name="category" value="goplay"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                GoPlay!
                            </label>
                        </div>
                        {/* <div className="form-check">
                        <input className="form-check-input" type="radio" name="category" value="friday"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                First Friday </label>
                            </div> */}
                        <div className="form-check">
                        <input className="form-check-input" onChange={handleChange} type="radio" name="category"  value="community"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Community </label>
                            </div>
                    </div>
                    {communitySpan == true && (
                         <span>
                        {/* <label>Community SubHeader</label> */}
                        <input className="form-control form-control-md" type="text" aria-label=".form-control-lg example"
                         maxLength="50"
                         value={commSubHeader}
                         placeholder="Optional: Community Subheader"
                         onChange={(e) => setCommSubHeader(e.target.value)}
                    ></input></span>
                    )}

                    <br></br>

                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" 
                    value={pin} 
                    onChange={(e) => setPin(!pin)}
                     />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                        Pin this post to the top.
                    </label>
                    </div>
                    <div className="col-auto">
                        <label className="errorMsg">{error}</label>
                        <br/>
                        <br/>
                        <Link to="/"><button type="button" className="btn btn-primary mb-3" onClick={handleUpload}>Upload</button></Link>
                    </div>
                    </form> 
                </div> 
            </div>
        </div>
    // </div>
    );
};

export default CreatePosts;

{/* <select className="form-select" id="floatingSelectGrid" aria-label="Floating label select example">
                        <option selected>Category</option>
                        <option value="MTRep">Montana Repertory Theatre</option>
                        <option value="Community">Community</option>
                        <option value="GoPlay">GoPlay!</option>
                    </select> */}