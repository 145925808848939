import React, {useState, useEffect} from 'react';
import { db } from '../../services/firebase';
import PlayFeed from '../Plays/PlayFeed';
import PostList from './PostList';
import "./styles.css"

export default function PostFeed({postId, setPostId, editPostId, setEditPostId}) {

    const [posts, setposts] = useState([]);

    useEffect(() => {
      db.collection("posts").orderBy("timestamp", "desc").onSnapshot((snapshot) =>
      {
          setposts(snapshot.docs.map((doc)=> ({id:doc.id, post: doc.data() })));
      })
    }, [])

    return (
        <div className="feed">
        <div>
            <div className="table table-borderless">
                <thead>
                    <tr>
                        {/* <th>Date</th> */}
                        <th>Post Title</th>
                        <th>Post Body</th>
                        <th>Photo Preview</th>
                        <th>Action</th>
                    </tr>
                </thead>  

            {posts.map(({id, post, })=>{
                return ( <PostList
                key={id}
                id={id}
                // timestamp={post.timestamp}
                postTitle={post.postTitle}
                photoUrl={post.photoUrl}
                postBody={post.postBody}
                postId={postId}
                setPostId={setPostId}
                editPostId={editPostId}
                setEditPostId={setEditPostId} 
                setDbPublish={post.publish}
                setDbPin={post.pinned}
                setDbCategory={post.categoryValue}
                // link={post.link}
                // timestamp={post.timestamp.Date}
                 />
               
            );
            })}
             </div>

    </div>
        </div>
    );
}
