import React, {useState} from 'react';
import { db, storage, firebase } from '../../services/firebase';
import DateTimePicker from 'react-datetime-picker';
import "./styles.css";


export default function CreateSponsors() {

    const [sponsorTitle, setSponsorTitle] = useState("");
    const [sponsorSubHeader, setSponsorSubHeader] = useState("");
    const [sponsorBody, setSponsorBody] = useState("");
    const [locationInfo, setLocationInfo] = useState("");
    const [linkName, setLinkName] = useState("");
    const [link, setLink] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [contentUrl, setContentUrl] = useState("");
    const [preImgUrl, setPreImgUrl] = useState("");
    const [ccUrl, setCCUrl] = useState("");
    const [categoryValue, setCategoryValue] = useState("");
    const [commSubHeader, setCommSubHeader] = useState("");
    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [geopoints, setGeopoints] = useState([
        { latitude: '', longitude: '', id: 0},
    ]);
    const [pin, setPin] = useState(false);
    const [inputKey, setInputKey]= useState("");
    const [communitySpan, setCommunitySpan] = useState();

    const [progress, setProgress] = useState(0);
    const [progressPre, setProgressPre] = useState(0);
    const [progressPlay, setProgressPlay] = useState(0);
    const [progressCC, setProgressCC] = useState(0);

    const [randomKey, setRandomKey] = useState("");
    const [randomKeyPre, setRandomKeyPre] = useState("");
    const [randomKeyPlay, setRandomKeyPlay] = useState("");
    const [randomKeyCC, setRandomKeyCC] = useState("");

    const [newImageName, setNewImageName] = useState("");
    const [newPreName, setNewPreName] = useState("");
    const [newPlayName, setNewPlayName] = useState("");
    const [newCCName, setNewCCName] = useState("");


    const [error, setError]= useState(false);
    const [errorTip, setErrorTip] = useState(false);

    const clearInputs = () => {
        setSponsorTitle('');
        setSponsorSubHeader('');
        setSponsorBody('');
        setLinkName('');
        setLink('');
        setImgUrl('');
        setPreImgUrl('');
        setContentUrl('');
        setCCUrl('');
        setCategoryValue('');
        setCommSubHeader('');
        setStart('');
        setEnd('');
        setPin('');
        setProgress(0);
        setProgressPre(0);
        setProgressPlay(0);
        setProgressCC(0);
        setGeopoints([{ latitude: '', longitude: '', id: ''},]);
        setError(false);
        setErrorTip(false);

    }


    let geoCounter = 0;

    const resetFileInput= () => {
        inputKey = Math.random().toString(36);
        setInputKey(inputKey);
    }

    const handleChangeInput = (id, e) => {
        
        const values = [...geopoints];
        values[id][e.target.name] = e.target.value;
        setGeopoints(values);
    }

    const handleAddFields = () => {
        setGeopoints([...geopoints, {latitude: '', longitude: '' , id: geoCounter++}])
    }

    const handleRemoveFields = (id) => {
        if( id > 0){
        const values = [...geopoints];
        values.splice(id,  1);
        setGeopoints(values);
        }else {
            return false;
        };
    };

    const handleCategoryChange = (e) =>{
    
        if(e.target.name){
            setCategoryValue(e.target.value);
            console.log(e.target.value);
        }
        if(e.target.value==="community"){
            setCommunitySpan(true);
        }else{
            setCommunitySpan(false);
        }
        
    };
    
    
    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     };
    
     const handleImgUpload = async (e) => {
        var imageName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`images/${imageName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
                
                
            },
            error => {
                console.log(error);
            },
        )
        setImgUrl(await fileref.getDownloadURL())  
        // console.log(fileref); 
        setNewImageName(imageName);
        // console.log(newImageName);
    }

    const handleRemoveMain = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`images/${newImageName}`)
            try {file.delete().then(function(){
                console.log("Main Image deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKey(randomString);
         setProgress(0);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }


    const handlePreImgUpload = async (e) => {
        var imagePreName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`images/${imagePreName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progressPre = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgressPre(progressPre);
            },
            error => {
                console.log(error);
            },
        )
        setPreImgUrl(await fileref.getDownloadURL())
        setNewPreName(imagePreName);
    }

    const handleRemovePreImgUpload = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`images/${newPreName}`)
            try {file.delete().then(function(){
                console.log("Preview Image deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKeyPre(randomString);
         setProgressPre(0);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }

    const handlePlayUpload = async (e) => {
        var playName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`playContent/${playName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progressPlay = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgressPlay(progressPlay);
            },
            error => {
                console.log(error);
            },
        )
        setContentUrl(await fileref.getDownloadURL())
        setNewPlayName(playName);
    }

    const handleRemovePlayUpload = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`playContent/${newPlayName}`)
            try {file.delete().then(function(){
                console.log("Play deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKeyPlay(randomString);
         setProgressPlay(0);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }


    const handleTranscriptUpload = async (e) => {
        var transcriptName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`transcripts/${transcriptName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progressCC = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgressCC(progressCC);
            },
            error => {
                console.log(error);
            },
        )
        setCCUrl(await fileref.getDownloadURL())
        setNewCCName(transcriptName);

    }

    const handleRemoveTranscriptUpload = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`transcripts/${newCCName}`)
            try {file.delete().then(function(){
                console.log("Transcript deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKeyCC(randomString);
         setProgressCC(0);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }

    function scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

     const handleUpload = (e) => {
        if(sponsorTitle != '' && start != '' && end != '' && categoryValue != ''  && imgUrl != '' && contentUrl != '' && geopoints != ''){
         db.collection("content").add({
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            title: sponsorTitle,
            subHeader: sponsorSubHeader,
            body: sponsorBody,
            locationInfo: locationInfo,
            mainPhotoUrl: imgUrl,
            previewPhotoUrl: preImgUrl,
            playUrl: contentUrl,
            TranscriptUrl: ccUrl,
            linkName: linkName,
            link: link,
            locationInfo: locationInfo,
            category: categoryValue,
            commSubHeader: commSubHeader,
            start: start,
            end: end,
            geopoints: firebase.firestore.FieldValue.arrayUnion(...geopoints),
            publish: false,
            views: 0,
            pinned: pin,
            type: "sponsor"

           
         });
         clearInputs();
         var randomString = makeid(9)

        setRandomKey(randomString);
        setRandomKeyPre(randomString);
        setRandomKeyPlay(randomString);
        setRandomKeyCC(randomString);
        scrollToTop();

        }else {
            setError("You are missing one or more components.");
            setErrorTip("*");
        }
     };

   
    return (
        <div className="CreateSponsor sponsor">
           <div>
               <h2>Sponsor Content</h2>
               <div>
               <label className="errorMsg">{errorTip}</label>
               <input className="form-control form-control-lg" type="text" placeholder="Sponsor Title or Business Name" aria-label=".form-control-lg example"
                        value={sponsorTitle}
                        onChange={(e) => setSponsorTitle(e.target.value)}
                    ></input>
                <br></br>
                <input className="form-control form-control-md" type="text" placeholder="Optional: SubHeader" aria-label=".form-control-lg example"
                        value={sponsorSubHeader}
                        onChange={(e) => setSponsorSubHeader(e.target.value)}
                    ></input>
                    <br></br>
                <div className="mb-3">
                    <textarea className="form-control" placeholder="Body" rows="3"
                        value={sponsorBody}
                        onChange={(e) => setSponsorBody(e.target.value)}
                    ></textarea>
                    </div>
                    <div className="mb-3">
                    <textarea className="form-control" placeholder="Information about Geopoint/Address" rows="2"
                        value={locationInfo}
                        onChange={(e) => setLocationInfo(e.target.value)}
                    ></textarea>
                    </div>
                    <div className="mb-3">
                    <label className="errorMsg">{errorTip}</label>
                    <label htmlFor="file-input" className="form-label">Main Image</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        key= {randomKey || ''}
                        accept="image/*"
                        onChange={handleImgUpload}
                    />
                    <progress max="100" value={progress}></progress>
                    <br/>
                   <button type="submit" className="btn btn-danger mb-3" onClick={handleRemoveMain}>Cancel</button>
                    </div>
                    <div className="mb-3">
                    <label htmlFor="file-input" className="form-label">Preview Image</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        accept="image/*"
                        key= {randomKeyPre || ''}
                        onChange={handlePreImgUpload}
                    />
                    <progress max="100" value={progressPre}></progress>
                    <br/>
                    <button type="submit" className="btn btn-danger mb-3" onClick={handleRemovePreImgUpload}>Cancel</button>
                    </div>
                <div className="mb-3">
                <label className="errorMsg">{errorTip}</label>
                    <label htmlFor="file-input" className="form-label">Choose Video or Audio to upload</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        accept="video/*, audio/*"
                        accept="video/*, audio/*"
                        key= {randomKeyPlay || ''}
                        onChange={handlePlayUpload}
                    />
                     <p>This may take awhile. . .</p>
                    <progress max="100" value={progressPlay}></progress>
                    <br/>
                   <button type="submit" className="btn btn-danger mb-3" onClick={handleRemovePlayUpload}>Cancel</button>
                    </div>
                    <div className="mb-3">
                    {/* <label className="errorMsg">{errorTip}</label> */}
                    <label htmlFor="file-input" className="form-label">Choose Transcript file to upload</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        key={randomKeyCC || ''}
                        onChange={handleTranscriptUpload}
                    />
                    <progress max="100" value={progressCC}></progress>
                    <br/>
                   <button type="submit" className="btn btn-danger mb-3" onClick={handleRemoveTranscriptUpload}>Cancel</button>
                    </div>
                <br></br>
                <div>
                    <input className="form-control form-control-md" type="text" placeholder="Link Name" aria-label=".form-control-lg example"
                        value={linkName}
                        onChange={(e) => setLinkName(e.target.value)}
                    ></input>
                    
                    <input className="form-control form-control-md" type="url" pattern="https?://.+" required placeholder="https://example.com" aria-label=".form-control-lg example"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    ></input>
                    </div>
                    <br></br>
                <div>
                    <label className="errorMsg">{errorTip}</label>
                    <label>Sponsor Start Date & Time</label>
                    <br></br>
                    <DateTimePicker className="btn-space form-control-md" onChange={setStart} value={start} />
                </div>
                <div>
                    <label className="errorMsg">{errorTip}</label>
                    <label>Sponsor End Date & Time</label>
                    <br></br>
                    <DateTimePicker className="btn-space form-control-md" onChange={setEnd} value={end} />
                </div>
                <br></br>
                {/* Category Section */}
                <div onChange={handleCategoryChange}>
                <label className="errorMsg">{errorTip}</label>
                        <div className="form-check">
                        <input className="form-check-input" type="radio" name="category" value="friday"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                First Friday </label>
                            </div>
                        <div className="form-check">
                        <input className="form-check-input" type="radio" name="category" value="community"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Community </label>
                            </div>
                    </div>
                    {communitySpan == true && (
                         <span>
                        {/* <label>Community SubHeader</label> */}
                        <input className="form-control form-control-md" type="text" aria-label=".form-control-lg example"
                         maxLength="50"
                         value={commSubHeader}
                         placeholder="Optional: Community Subheader"
                         onChange={(e) => setCommSubHeader(e.target.value)}
                    ></input></span>
                    )}
                    <br/>

                    <br></br>
                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" 
                    value={pin} 
                    onChange={(e) => setPin(!pin)}
                     />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                        Pin this post to the top.
                    </label>
                    </div>

                    <br></br>
                <h6>Geopoints</h6>
                { geopoints.map((geopoint, id)=> (
                    <div className="sponsors__geopoints_div" key={id}>
                        <input 
                        className="form-control form-control-md sponsors__geopoints"
                        name="latitude" 
                        label="Latitude" 
                        value={geopoint.latitude} 
                        type="number"
                        onChange={(e) => handleChangeInput(id, e)}
                        
                        />
                        <input  
                        className="form-control form-control-md sponsors__geopoints"
                        name="longitude" 
                        label="Longitude" 
                        value={geopoint.longitude} 
                        type="number"
                        onChange={(e) => handleChangeInput(id, e)}
                        
                        />
                        
                        <a title="Remove" className="btn btn-danger btn-space" onClick={()=> handleRemoveFields(id)}>
                            <i className="fas fa-minus"></i>
                        </a>
                        <a title="Add" className="btn btn-success btn-space" onClick={()=> handleAddFields()}>
                            <i className="fas fa-plus"></i>
                        </a>
                        
                    </div>
                )) }
                
                <br></br>
                <label className="errorMsg">{error}</label>
                    <div className="col-auto">
                        <button type="button" className="btn btn-primary mb-3" onClick={handleUpload} >Upload</button>
                    </div>
                   

               </div>


           </div>
        </div>
    )
}
