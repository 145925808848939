import React, { useState} from 'react';
import CreateUsers from './CreateUsers';
import UserFeed from './UserFeed';
import ShowUser from './ShowUser';
import EditUser from './EditUser';
import {
  BrowserRouter as Router,
  Route, Link, Switch
} from 'react-router-dom';
import './styles.css';


const UsersPage = () => {
    const [userId, setUserId] = useState();
    const [editUserId, setEditUserId] = useState();
  
    const [showResults, setShowResults] = useState(false);
    const showCreateUsers = () => setShowResults(true);


    return(
        <div>
          <Router>
          <div className="page_title">
            <h1>Users</h1>
            {/* <h3>🚧Under Construction🚧</h3> */}
            <button disabled={true} className="btn myButton" onClick={showCreateUsers}>Add Users</button>
            </div>
            
            <div className="section__half">
            <UserFeed
            userId={userId}
            setUserId={setUserId}
            editUserId={editUserId}
            setEditUserId={setEditUserId}
            />
            {/* <button className="btn myButton">Export</button> */}
            </div>
            

            {/* <div className="section"><ShowUser userId={userId} setUserId={setUserId}/></div> */}

            {/* <div>
            {showResults ? <div className="section"><CreateUsers/></div> : null }
            </div> */}

            <div className="section">
              <Switch>
              <Route path="/EditUser">
              <EditUser editUserId={editUserId} setEditUserId={setEditUserId} />
              </Route>
              </Switch>
            </div>
            </Router>
        </div>
    );
};

export default UsersPage;