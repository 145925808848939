import React from 'react';
import "./styles.css"

export default function ShowSponsor({sponsorId}) {
    if(sponsorId == null){
        return null;
    }
        let millisecondsStart;
        let  millisecondsEnd;
        let  startDate;
        let  endDate;
        let  startDateFormat;
        let  endDateFormat;
        let startFormat;
    
    
        try{
        millisecondsStart= sponsorId.sponsor.start.toDate()
        millisecondsEnd = sponsorId.sponsor.end.toDate()
        startDate = new Date(millisecondsStart)
        endDate = new Date(millisecondsEnd)
        startFormat = startDate.toLocaleDateString("en-US")
        startDateFormat = startDate.toLocaleString("en-US",  {timeZoneName: "short"})
        endDateFormat = endDate.toLocaleString("en-US", {timeZoneName: "short"})
        }catch{
            return null;
        }
    
        
    
    return (
        <div className="sponsor">
        <div className="sponsor__header">
            {/* <div className="sponsor__headerLeft"><h2>{sponsorId.sponsor.sponsorTitle}</h2></div> */}
            {/* <div className="sponsor__headerLeft"><h3>{sponsorId.sponsor.category}</h3></div> */}
            

        </div>
        <div >
            <img style={{
                width: "100%",
                objectfit: "contain",
            }} alt="" src={sponsorId.sponsor.mainPhotoUrl} />
        </div>
        <div>
            <audio controls>
                <source src={sponsorId.sponsor.playUrl} type="audio/mp4" />
                Your browser does not support the audio tag.
            </audio>

        </div>
        <div className="sponsor__center">
            <div className="sponsor__headerLeft"><h3>{sponsorId.sponsor.subHeader}</h3></div>
            <div className="sponsor__body"><p>Body: {sponsorId.sponsor.sponsorBody}</p></div>
            <div className="play__body"><p>Location/Address: {sponsorId.sponsor.locationInfo}</p></div>
            <div className="sponsor__body"><p>Views: {sponsorId.sponsor.views}</p></div>
            <div >
            <div className="play__body"><p>Pinned: {`${sponsorId.sponsor.pinned}`}</p></div>
            <p>Schedule/Preview Photo:</p>      
            <img style={{
                width: "100%",
                objectfit: "contain",
            }} alt="" src={sponsorId.sponsor.previewPhotoUrl} />
            </div>
            {/* <div className="sponsor__body"><p>Transcript: {sponsorId.sponsor.TranscriptUrl}</p></div> */}
            <div>Start Date: {`${startDateFormat}`}</div>
            <div>End Date: {`${endDateFormat}`}</div>
            <br/>
            <div>Event Key: {sponsorId.id}</div>
            <br/>
            
            <div className=" sponsor__link">Link Name: {sponsorId.sponsor.linkName}</div>
            <div className=" sponsor__link">Link:<a href={ `${sponsorId.sponsor.link}`}>{sponsorId.sponsor.link}</a></div>
            <br/>
            <div>
                <h5>Geopoint(s)</h5>
                {sponsorId.sponsor.geopoints.map(geopoint => (
                    <>
                        <p>Latitude: {geopoint.latitude} | Longitude: {geopoint.longitude}</p>
                        <hr/>
                    </>
                ))}

            </div>
        
        </div>
    </div>
    )
}
