import React, {useState, useEffect} from 'react';
import { db, storage, firebase } from '../../services/firebase';
import DateTimePicker from 'react-datetime-picker';
import {Link} from 'react-router-dom';
import './styles.css'

export default function EditPlay({editPlayId, setEditPlayId,}) {
    
    const [playTitle, setPlayTitle] = useState("");
    const [playSubHeader, setPlaySubHeader] = useState("");
    const [screenwrite, setScreenwrite] = useState("");
    const [copyright, setCopyright] = useState("");
    const [actorInfo, setActorInfo] = useState("");
    const [addInfo, setAddInfo] = useState("");
    const [playBody, setPlayBody] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [preImgUrl, setPreImgUrl] = useState("");
    const [playUrl, setPlayUrl] = useState("")
    const [ccUrl, setCCUrl] = useState("");
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [typeVar, setTypeVar] = useState();
    const [type, setType] = useState();
    const [pin, setPin] = useState();
    const [geopoints, setGeopoints] = useState([
        {latitude: '', longitude: '', id: 0},
    ]);
    const [funnyman, setFunny] = useState([
        {latitude: '', longitude: '', id: 0},
    ]);
    const [locationInfo, setLocationInfo] = useState("");

    const [progress, setProgress] = useState(0);
    const [progressPre, setProgressPre] = useState(0);
    const [progressPlay, setProgressPlay] = useState(0);
    const [progressCC, setProgressCC] = useState(0);

    const [randomKey, setRandomKey] = useState("");
    const [randomKeyPre, setRandomKeyPre] = useState("");
    const [randomKeyPlay, setRandomKeyPlay] = useState("");
    const [randomKeyCC, setRandomKeyCC] = useState("");

    const [newImageName, setNewImageName] = useState("");
    const [newPreName, setNewPreName] = useState("");
    const [newPlayName, setNewPlayName] = useState("");
    const [newCCName, setNewCCName] = useState("");

    const [updateMainImg, setUpdateMainImg] = useState(false);
    const [updatePreImg, setUpdatePreImg] = useState(false);
    const [updatePlay, setUpdatePlay] = useState(false);
    const [updateCC, setUpdateCC] = useState(false);

    const [error, setError]= useState(false);
    const [errorTip, setErrorTip] = useState(false);


    let millisecondsStart;
    let  millisecondsEnd;
    let  startDate;
    let  endDate;
    let  startDateFormat;
    let  endDateFormat;

    let coolVari = 1;
    


    useEffect(() => {
        try{
        if(editPlayId !== undefined){
        if (editPlayId.play.type == 'play'){
            setTypeVar(false);
            setType('play');
            
        } else {
            setTypeVar(true);   
            setType('easterEgg');
        }

        }
    }catch{
        return null
    }
    
       }, [editPlayId])

       if(editPlayId == null){
        return null;

    } 
 

  


    let geoCounter = 0;

    const handleChangeInput = (id, e) => {
        
        const values = [...geopoints];
        values[id][e.target.name] = e.target.value;
        setGeopoints(values);
    }

    const handleAddFields = () => {
        setGeopoints([...geopoints, {latitude: '', longitude: '' , id: geoCounter++}])
    }

    const handleRemoveFields = (id) => {
        if( id > 0){
        const values = [...geopoints];
        values.splice(id,  1);
        setGeopoints(values);
        }else {
            return false;
        };
    };

    const clearInputs = () => {
        setPlayTitle('');
        setPlaySubHeader('');
        setScreenwrite('');
        setCopyright('');
        setActorInfo('');
        setAddInfo('');
        setPlayBody('');
        setImgUrl('');
        setPreImgUrl('');
        setPlayUrl('');
        setCCUrl('');
        setStart('');
        setEnd('');
        setLocationInfo('');
        setPin('');
        setProgress(0);
        setProgressPre(0);
        setProgressPlay(0);
        setProgressCC(0);
        setGeopoints([{ latitude: '', longitude: '', id: 0},]);
        setError(false);
        setErrorTip(false);

    }

    const changePin = () => {
        editPlayId.play.pinned=!editPlayId.play.pinned;
        setPin(editPlayId.play.pinned);
    }


    // var toggleValue;
   
    
    

    console.log('<-----Type------>')
        // console.log(typeVar)
        console.log(`${type}` + `${typeVar}`)

    const changeToggle = () => {
        //I don't know why this works sorry
        if (typeVar == true){
            setType('play')
       } else {
           setType('easterEgg')
       }
       setTypeVar(!typeVar)
    }
   
    const updateTitle = (e) => {
        if(e.target.value == ''){
            setPlayTitle(' ');
        }
        else{
            setPlayTitle(e.target.value)
        }
    }

    const updateSubHeader = (e) => {
        if(e.target.value == ''){
            setPlaySubHeader(' ');
        }
        else{
            setPlaySubHeader(e.target.value)
        }
    }
    const updateScreenwrite = (e) => {
        if(e.target.value == ''){
            setScreenwrite(' ');
        }
        else{
            setScreenwrite(e.target.value)
        }
    }
    const updateCopyright = (e) => {
        if(e.target.value == ''){
            setCopyright(' ');
        }
        else{
            setCopyright(e.target.value)
        }
    }
    const updateBody = (e) =>{
        if(e.target.value == ''){
            setPlayBody(' ');  
        }
        else{
            
            setPlayBody(e.target.value)     
        }
    }
    const updateLocationInfo = (e) => {
        if(e.target.value==''){
            setLocationInfo(' ')
        }
        else{
            setLocationInfo(e.target.value)
        }
    }
    const updateActorInfo = (e) => {
        if(e.target.value==''){
            setActorInfo(' ')
        }
        else{
            setActorInfo(e.target.value)
        }
    }
    const updateAddInfo = (e) => {
        if(e.target.value==''){
            setAddInfo(' ')
        }
        else{
            setAddInfo(e.target.value)
        }
    }


    try{
        millisecondsStart= editPlayId.play.start.toDate()
        millisecondsEnd = editPlayId.play.end.toDate()
        startDate = new Date(millisecondsStart)
        endDate = new Date(millisecondsEnd)
        startDateFormat = startDate.toLocaleString("en-US",  {timeZoneName: "short"})
        endDateFormat = endDate.toLocaleString("en-US", {timeZoneName: "short"})
    
    
        }catch{
            return null;
    
        }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        }
    
    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     };

     //Main Image//
     const handleImgUpdate = async (e) => {
        var imageName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`images/${imageName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
                setUpdateMainImg(true);
            },
            error => {
                console.log(error);
            },
        )
        setImgUrl(await fileref.getDownloadURL())   
        setNewImageName(imageName);
    }

    const removeMainImgDb = () => {
        var imageRef = storage.refFromURL(editPlayId.play.mainPhotoUrl)
        console.log(imageRef)
        imageRef.delete().then(function(){
            console.log("delete main image success");
        }).catch(function(error){
            console.log(`Error ${error}`);
        });
        
    }

    const handleRemoveMain = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`images/${newImageName}`)
            try {file.delete().then(function(){
                console.log("Main Image deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKey(randomString);
         setProgress(0);
         setUpdateMainImg(false);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }
    //Preview Image//
    const handlePreImgUpdate = async (e) => {
        var imagePreName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`images/${imagePreName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progressPre = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgressPre(progressPre);
                setUpdatePreImg(true);
            },
            error => {
                console.log(error);
            },
        )
        setPreImgUrl(await fileref.getDownloadURL())
        setNewPreName(imagePreName)
    }

    const removePreImgDb = () => {
        var imageRef = storage.refFromURL(editPlayId.play.previewPhotoUrl)
        console.log(imageRef)
        imageRef.delete().then(function(){
            console.log("delete preview Db success");
        }).catch(function(error){
            console.log(`Error ${error}`);
        });
        
    }

    const handleRemovePreImg = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`images/${newPreName}`)
            try {file.delete().then(function(){
                console.log("Preview Image deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKeyPre(randomString);
         setProgressPre(0);
         setUpdatePreImg(false);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }

    //Play//
    const handlePlayUpdate = async (e) => {
        var playName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`playContent/${playName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progressPlay = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgressPlay(progressPlay);
                setUpdatePlay(true);
            },
            error => {
                console.log(error);
            },
        )
        setPlayUrl(await fileref.getDownloadURL())
        setNewPlayName(playName)
    }
    const removePlayDb = () => {
        var imageRef = storage.refFromURL(editPlayId.play.playUrl)
        console.log(imageRef)
        imageRef.delete().then(function(){
            console.log("delete Play from DB success");
        }).catch(function(error){
            console.log(`Error ${error}`);
        });
        
    }

    const handleRemovePlay = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`playContent/${newPlayName}`)
            try {file.delete().then(function(){
                console.log("Play deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKeyPlay(randomString);
         setProgressPlay(0);
         setUpdatePlay(false);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }

    //Transcript//
    const handleTranscriptUpdate = async (e) => {
        var transcriptName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`transcripts/${transcriptName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progressCC = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgressCC(progressCC);
                setUpdateCC(true);
            },
            error => {
                console.log(error);
            },
        )
        setCCUrl(await fileref.getDownloadURL())
        setNewCCName(transcriptName)

    }
    const removeCCDb = () => {
        var imageRef = storage.refFromURL(editPlayId.play.TranscriptUrl)
        console.log(imageRef)
        imageRef.delete().then(function(){
            console.log("delete transcript from DB success");
        }).catch(function(error){
            console.log(`Error ${error}`);
        });
        
    }

    const handleRemoveTranscript = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`transcripts/${newCCName}`)
            try {file.delete().then(function(){
                console.log("Transcript deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKeyCC(randomString);
         setProgressCC(0);
         setUpdateCC(false);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }

    


    const handleUpdate = () => {
    if(playTitle != ' ' && start != ' ' && end != ' ' && playUrl != ' ' && imgUrl != ' ' && geopoints != ' '){
        if (window.confirm('Are you sure you wish to update this play?')){
            if(updateMainImg==true){
                removeMainImgDb();
            }
            if(updatePreImg==true){
                removePreImgDb();
            }
            if(updatePlay==true){
                removePlayDb();
            }
            if(updateCC==true){
                removeCCDb();
            }
            // if(geopoints ==! [{latitude: '', longitude: '', id: 0}]){
            db.collection("content").doc(editPlayId.id).update({
            // timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            title: playTitle || editPlayId.play.title,
            subHeader: playSubHeader || editPlayId.play.subHeader,
            screenwriter: screenwrite || editPlayId.play.screenwriter,
            copyrightDate: copyright || editPlayId.play.copyrightDate,
            actorInfo: actorInfo || editPlayId.play.actorInfo,
            addInfo: addInfo || editPlayId.play.addInfo,
            body: playBody  || editPlayId.play.body,
            mainPhotoUrl: imgUrl || editPlayId.play.mainPhotoUrl,
            previewPhotoUrl: preImgUrl || editPlayId.play.previewPhotoUrl,
            playUrl: playUrl || editPlayId.play.playUrl,
            TranscriptUrl: ccUrl || editPlayId.play.TranscriptUrl,
            start: start || editPlayId.play.start,
            end: end || editPlayId.play.end,
            type: type || editPlayId.play.type,
            pinned: pin || editPlayId.play.pinned,
            locationInfo: locationInfo || editPlayId.play.locationInfo,
            geopoints:firebase.firestore.FieldValue.arrayUnion(...geopoints) || editPlayId.play.geopoints,
            });
        } else {
            return false;
        }
        clearInputs();
        var randomString = makeid(9)

        setRandomKey(randomString);
        setRandomKeyPre(randomString);
        setRandomKeyPlay(randomString);
        setRandomKeyCC(randomString);
        scrollToTop();

    }else {
        setError("You are missing one or more components.");
        setErrorTip("*");
    }
    };

    const removeGeo = (id,lat,long) => {
        console.log(id);
        let array = [editPlayId.play.geopoints];
        const obj = {id:id, latitude: lat, longitude: long}
        db.collection("content").doc(editPlayId.id).update({
            geopoints: firebase.firestore.FieldValue.arrayRemove(obj)
        });
        
     }


    return (
        <div className="createPlay play">
            <div>
                    <h2>Edit a Play</h2>
                <div>
                     <label className="errorMsg">{errorTip}</label>
                     <label>Title</label>
                    <input className="form-control form-control-lg" type="text" placeholder={`${editPlayId.play.title}`} aria-label=".form-control-lg example"
                        maxLength="50"
                        value={playTitle || editPlayId.play.title}
                        onChange={updateTitle}
                    ></input>
                    <br></br>
                    <label>Optional: Subheader</label>
                    <input className="form-control form-control-md" type="text" placeholder={`${editPlayId.play.subHeader}`} aria-label=".form-control-lg example"
                        value={playSubHeader || editPlayId.play.subHeader}
                        onChange={updateSubHeader}
                    ></input>
                    <br></br>
                    <label>Screenwriter</label>
                    <input className="form-control form-control-md" type="text" placeholder={`${editPlayId.play.screenwriter}`} aria-label=".form-control-lg example"
                        value={screenwrite || editPlayId.play.screenwriter}
                        onChange={updateScreenwrite}
                    ></input>
                    <br></br>
                    <label>Copyright Date</label>
                    <input className="form-control form-control-md" type="number" placeholder={`${editPlayId.play.copyrightDate}`} aria-label=".form-control-lg example"
                        value={copyright || editPlayId.play.copyrightDate}
                        onChange={updateCopyright}
                    ></input>
                    <br></br>
                    <div className="mb-3">
                    <label>Body</label>
                    <textarea className="form-control" placeholder={`${editPlayId.play.body}`} rows="4"
                        value={playBody || editPlayId.play.body}
                        onChange={updateBody}
                    ></textarea>
                    <br/>
                     <div className="mb-3">
                     <label>Location Info/ Address</label>
                    <textarea className="form-control" placeholder={`${editPlayId.play.locationInfo}`} rows="2"
                        value={locationInfo || editPlayId.play.locationInfo}
                        onChange={updateLocationInfo}
                    ></textarea>
                    </div>
                    <br></br>
                    <div>
                    {/* <label className="errorMsg">{errorTip}</label> */}
                    <label>Event Start Date & Time</label>
                    <br/>
                    <label>Current: {`${startDateFormat}`}</label>
            
                    <DateTimePicker className="btn-space form-control-md" onChange={setStart} value={start}/>
                </div>
                <br/>
                <div>
                    {/* <label className="errorMsg">{errorTip}</label> */}
                    <label>Event End Date & Time</label>
                    <br/>
                    <label>Current: {`${endDateFormat}`}</label>
                    <DateTimePicker className="btn-space form-control-md" onChange={setEnd} value={end} />
                </div>
                <br></br>
                    </div>
                    <div className="mb-3">
                    <label>Actor Info</label>
                    <textarea className="form-control" placeholder={`${editPlayId.play.actorInfo}`} rows="2"
                        value={actorInfo || editPlayId.play.actorInfo}
                        onChange={updateActorInfo}
                    ></textarea>
                    </div>
                   
                    <div className="mb-3">
                    <label>Additional Info</label>
                    <textarea className="form-control" placeholder={`${editPlayId.play.addInfo}`} rows="2"
                        value={addInfo || editPlayId.play.addInfo}
                        onChange={updateAddInfo}
                    ></textarea>
                    </div>
                    <div className="mb-3">
                    <label className="errorMsg">{errorTip}</label>
                    <label htmlFor="file-input" className="form-label">Main Image</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        accept="image/*"
                        key= {randomKey || ''}
                        onChange={handleImgUpdate}
                    />
                     <progress max="100" value={progress}></progress>
                     <br/>
                     <button type="submit" className="btn btn-danger mb-3" onClick={handleRemoveMain}>Cancel</button>
                    </div>
                    <div className="mb-3">
                    <label htmlFor="file-input" className="form-label">Preview Image</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        accept="image/*"
                        key= {randomKeyPre || ''}
                        onChange={handlePreImgUpdate}
                    />
                     <progress max="100" value={progressPre}></progress>
                     <br/>
                     <button type="submit" className="btn btn-danger mb-3" onClick={handleRemovePreImg}>Cancel</button>
                    </div>
                    <div className="mb-3">
                    <label className="errorMsg">{errorTip}</label>
                    <label htmlFor="file-input" className="form-label">Choose Video or Audio to Update</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        accept="video/*, audio/*"
                        key= {randomKeyPlay || ''}
                        onChange={handlePlayUpdate}
                    />
                     <p>This may take awhile. . .</p>
                    <progress max="100" value={progressPlay}></progress>
                    <br/>
                    <button type="submit" className="btn btn-danger mb-3" onClick={handleRemovePlay}>Cancel</button>
                    </div>
                    <div className="mb-3">
                    {/* <label className="errorMsg">{errorTip}</label> */}
                    <label htmlFor="file-input" className="form-label">Choose Transcript file to Update</label>
                    <input
                        className="form-control"
                        id="file-input"
                        type="file"
                        key= {randomKeyCC || ''}
                        onChange={handleTranscriptUpdate}
                    />
                     <progress max="100" value={progressCC}></progress>
                     <br/>
                     <button type="submit" className="btn btn-danger mb-3" onClick={handleRemoveTranscript}>Cancel</button>
                    </div>
                    <br></br>
                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" 
                    // value={pin} 
                    checked={editPlayId.play.pinned}
                    onChange={changePin}
                     />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                        Pin this play to the top.
                    </label>
                    </div>
                    <br/>
                    <div >
                    <p className="typeSet">Play</p>
                    <label className="switch typeSet">
                    <input type="checkbox" 
                        checked={typeVar}
                        onClick={changeToggle}
                    />
                    <span className="slider round"></span>
                    </label>
                    <p className="typeSet">Easter Egg</p>
                    </div>
                    <br/>


                    <br></br>
                    <h6>Geopoints</h6>
                    <label className="errorMsg">{errorTip}</label>
                    <div>
                {editPlayId.play.geopoints.map(geopoint => (
                    <>
                        {(function () {
                             for(let i = 0; i<editPlayId.play.geopoints.length;i++){
                                if(geopoint.latitude != `` && geopoint.longitude != ``){
                                    return(
                                        <>
                                        <p>Latitude: {geopoint.latitude} | Longitude: {geopoint.longitude}<button type="button"
                                            onClick={()=> removeGeo(geopoint.id,geopoint.latitude,geopoint.longitude)}>Delete</button></p> 
                                            <hr/>
                                        </>
                                    )
                                }
                            }
                        })()}
                       
                    </>
                ))}
            </div>
                { geopoints.map((geopoint, id)=> (
                    <div className="play__geopoints_div" key={id}>
                        <label>Latitude</label>
                        <input 
                        className="form-control form-control-md play__geopoints"
                        name="latitude" 
                        label="Latitude" 
                        value={geopoint.latitude}  
                        type="number"
                        onChange={(e) => handleChangeInput(id, e)}
                        />
                        <label>Longitude</label>
                        <input  
                        className="form-control form-control-md play__geopoints"
                        name="longitude" 
                        label="Longitude" 
                        value={geopoint.longitude} 
                        type="number"
                        onChange={(e) => handleChangeInput(id, e)}
                        
                        />
                        
                        <a title="Remove" className="btn btn-danger btn-space" 
                        onClick= {()=> handleRemoveFields(id)}>
                            <i className="fas fa-minus"></i>
                        </a>
                        <a title="Add" className="btn btn-success btn-space" 
                        onClick={()=> handleAddFields()}>
                            <i className="fas fa-plus"></i>
                        </a>
                        
                    </div>
                )) }
                   
                   <br></br>
                <label className="errorMsg">{error}</label>
                <br/>
                    <div className="col-auto">
                        <button type="button" className="btn btn-warning mb-3" onClick={handleUpdate}>Update</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
