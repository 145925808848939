import React, {useState, useEffect} from 'react';
import { db } from '../../services/firebase';
import SponsorList from './SponsorList';
import "./styles.css"


export default function SponsorFeed({
    sponsorId, 
    setSponsorId,
    editSponsorId,
    setEditSponsorId}) {

        const [sponsors, setSponsors] = useState([]);
        

        useEffect(() => {
             db.collection("content").where("type", "==", "sponsor").limit(25).orderBy("timestamp", "desc")
            .onSnapshot((snapshot) =>{
                setSponsors(snapshot.docs.map((doc)=> ({id:doc.id, sponsor: doc.data() })));
            })
               
        }, [])

  return (
    <div className="feed">
        <div>
        <div>
            {/* <div>List of Sponsors</div> */}
        </div>
        <div className="table table-borderless">
            <thead>
                <tr>
                    {/* <th>Date</th> */}
                    <th>Sponsor Title</th>
                    <th>Sponsor Body</th>
                    <th>Photo Preview</th>
                    <th>Action</th>
                </tr>
            </thead>  

            {sponsors.map(({id, sponsor, })=>{
                return ( <SponsorList
                key={id}
                id={id}
                sponsorTitle={sponsor.title}
                mainPhotoUrl={sponsor.mainPhotoUrl}
                sponsorBody={sponsor.body}
                sponsorView={sponsor.views}
                sponsorId={sponsorId}
                setSponsorId={setSponsorId}
                editSponsorId={editSponsorId}
                setEditSponsorId={setEditSponsorId} 
                setDbPublish={sponsor.publish}
                // link={Sponsor.link}
                // timestamp={Sponsor.timestamp.Date}
                 />
               
            );
            })}
           
             </div>

    </div>
        </div>
  );
}
