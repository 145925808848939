import React, {useState, useEffect} from 'react';
import { db } from '../../services/firebase';
import PlayList from './PlayList';
import "./styles.css"

export default function PlayFeed({
    playId, 
    setPlayId,
    editPlayId,
    setEditPlayId, 
    publish
}) {
    const [plays, setPlays] = useState([]);
    useEffect(() => {
        db.collection("content").where("type", "in", ['play', 'easterEgg']).limit(25).orderBy("timestamp", "desc")
        .onSnapshot((snapshot) =>
        {
            setPlays(snapshot.docs.map((doc)=> ({id:doc.id, play: doc.data() })));
        })
      }, [])

    return (
        <div className="feed">
        <div>
        <div>
            {/* <div>List of plays</div> */}
        </div>
        <div className="table table-borderless">
            <thead>
                <tr>
                    {/* <th>Date</th> */}
                    <th>Play Title</th>
                    <th>Type</th>
                    <th>Play Body</th>
                    <th>Photo Preview</th>
                    <th>Views</th>
                    <th>Action</th>
                </tr>
            </thead>  

            {plays.map(({id, play, })=>{
                return ( <PlayList
                key={id}
                id={id}
                playTitle={play.title}
                type={play.type}
                mainPhotoUrl={play.mainPhotoUrl}
                playBody={play.body}
                playView={play.views}
                playId={playId}
                setPlayId={setPlayId}
                editPlayId={editPlayId}
                setEditPlayId={setEditPlayId} 
                setDbPublish= {play.publish}

                // link={play.link}
                // timestamp={play.timestamp.Date}
                 />
               
            );
            })}
             </div>

    </div>
        </div>
    );
}
