import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
// import * as admin from "firebase/admin";

// const {REACT_APP_FIREBASE_KEY, REACT_APP_FIREBASE_DOMAIN, REACT_APP_FIREBASE_DATABASE,
//   REACT_APP_FIREBASE_PROJECT_ID, REACT_APP_FIREBASE_STOARGE_BUCKET, REACT_APP_FIREBASE_SENDER_ID,REACT_APP_MESSAGING_APP_ID} = process.env;

// TODO: Move this to an env file before commiting
export const firebaseConfig = {
apiKey: process.env.REACT_APP_FIREBASE_KEY,
authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
appId: process.env.REACT_APP_MESSAGING_APP_ID,
};

// Debugging for later!
if (!firebaseConfig.apiKey) throw new Error("Missing firebase credential: apiKey");
if (!firebaseConfig.authDomain) throw new Error("Missing firebase credential: authDomain");
if (!firebaseConfig.databaseURL) throw new Error("Missing firebase credential: databaseURL");
if (!firebaseConfig.projectId) throw new Error("Missing firebase credential: projectId");
if (!firebaseConfig.storageBucket) throw new Error("Missing firebase credential: storageBucket");
if (!firebaseConfig.messagingSenderId) throw new Error("Missing firebase credential: messagingSenderId");
if (!firebaseConfig.appId) throw new Error("Missing firebase credential: appId");


firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

// const ref = firebase.firestore().collection('Montana Rep app');


// var admin = require("firebase-admin");

// var serviceAccount = require("path/to/serviceAccountKey.json");

// admin.initializeApp({
//   credential: admin.credential.cert(serviceAccount),
//   databaseURL: "https://montana-rep-app-8a22e-default-rtdb.firebaseio.com"
// });

// var admin = require('firebase-admin');

//  admin.initializeApp({
//   credential: admin.credential.applicationDefault(),
//   // databaseURL: 'https://<DATABASE_NAME>.firebaseio.com'
// });

// const admin = firebase.admin();

export {db, storage, auth, firebase};

const options = {};
 
// const dataProvider = FirebaseDataProvider(firebaseConfig, options);