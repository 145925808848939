import React, { useState} from 'react';
import {db, storage, firebase} from '../../services/firebase';
// import {Link} from 'react-router-dom';\
import validator from 'validator';
import DateTimePicker from 'react-datetime-picker';
import "./styles.css";

export default function EditEvent({editEventId, setEditEventId, photoUrl, eventId}) {

    const [eventTitle, setEventTitle] = useState("");
    const [eventBody, setEventBody] = useState("");
    const [linkName, setLinkName] = useState("");
    const [link, setLink] = useState("");
    const [locationInfo, setLocationInfo] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [categoryValue, setCategoryValue] = useState("");
    const [commSubHeader, setCommSubHeader] = useState("");
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [progress, setProgress] = useState(0);
    const [pin, setPin] = useState();

    const [keyId, setKeyId ] = useState('');
    const [error, setError]= useState(false);
    const [errorTip, setErrorTip] = useState(false);
    const [errorURLmessage, setErrorURLmessage] = useState('');

    const [tempFile, setTempFile] = useState("");
    const [randomKey, setRandomKey] = useState("");
    const [updateImg, setUpdateImg] = useState(false);

    const [communitySpan, setCommunitySpan] = useState();
    const [filterName, setFilterName] = useState();

    const [geopoints, setGeopoints] = useState([
        { latitude: '', longitude: '', id: 0},
    ]);
    let millisecondsStart;
    let  millisecondsEnd;
    let  startDate;
    let  endDate;
    let  startDateFormat;
    let  endDateFormat;

    

    if(editEventId == null){
        return null;
    }

    let geoCounter = 0;
    const handleChangeInput = (id, e) => {
        
        const values = [...geopoints];
        values[id][e.target.name] = e.target.value;
        setGeopoints(values);
    }

    const handleAddFields = () => {
        setGeopoints([...geopoints, {latitude: '', longitude: '' , id: geoCounter++}])
    }

    const handleRemoveFields = (id) => {
        if( id > 0){
        const values = [...geopoints];
        values.splice(id,  1);
        setGeopoints(values);
        }else {
            return false;
        };
    };

  
   
   const clearInputs = () => {
        setEventTitle('');
        setEventBody('');
        setLinkName('');
        setLink('');
        setLocationInfo('');
        setFileUrl('');
        setCategoryValue('');
        setCommSubHeader('');
        setStart('');
        setEnd('');
        setPin('');
        setFileUrl('');
        setProgress(0);
        setKeyId('');
        setGeopoints([{ latitude: '', longitude: '', id: 0},]);
        setError(false);
        setErrorTip(false);
        
    }

    const changePin = () => {
        editEventId.event.pinned=!editEventId.event.pinned;
        setPin(editEventId.event.pinned);
    }


    //Some gross code below//
    const updateTitle = (e) => {
        if(e.target.value == ''){
            setEventTitle(' ');
        }
        else{
            setEventTitle(e.target.value)
        }
    }

    const updateBody = (e) =>{
        if(e.target.value == ''){
            setEventBody(' ');  
        }
        else{
            
            setEventBody(e.target.value)     
        }
    }
    const updateLocationInfo = (e) => {
        if(e.target.value==''){
            setLocationInfo(' ')
        }
        else{
            setLocationInfo(e.target.value)
        }
    }

    const updateLinkName = (e) =>{
        if(e.target.value==''){
            setLinkName(' ')
        }
        else{
            setLinkName(e.target.value)
        }
    }

    const updateLink = (e) =>{
        if(e.target.value == ''){
            setLink(' ')
        }
        else{
            setLink(e.target.value)
        }
    }

    const updateCommSubHeader = (e) =>{
        if(e.target.value==''){
            setCommSubHeader(' ')
        }
        else{
            setCommSubHeader(e.target.value)
        }
    }

    try{
    millisecondsStart= editEventId.event.start.toDate()
    millisecondsEnd = editEventId.event.end.toDate()
    startDate = new Date(millisecondsStart)
    endDate = new Date(millisecondsEnd)
    startDateFormat = startDate.toLocaleString("en-US",  {timeZoneName: "short"})
    endDateFormat = endDate.toLocaleString("en-US", {timeZoneName: "short"})


    }catch{
        return null;

    }
    
    const validate = (value) => {
        if (validator.isURL(value)) {
           return true
        } else {
            setErrorURLmessage('Is Not Valid URL')
            return false
        }
      }

    function scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

    const filterValue = () => {
        if(editEventId.event.category === "mtrep"){
            setFilterName("Montana Repertory Theatre")
        }
        if(editEventId.event.category === "community"){
            setFilterName("Community")
        }
    }

    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     };

    

     const onFileUpdate = async (e) => {     
        var imageName = makeid(10);
        const file = e.target.files[0]
        const fileref = storage.ref().child(`images/${imageName}`)
        await fileref .put(file)
        let uploadTask = fileref.put(file);
        uploadTask.on("state_changed", function(snapshot){
                var progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
                setUpdateImg(true);
            },
            error => {
                console.log(error);
            },
        )
        setFileUrl(await fileref.getDownloadURL())  
        setTempFile(imageName)
        console.log(tempFile);
        
     }
     const handleCategoryChange = (e) =>{
        if(e.target.name){
            setCategoryValue(e.target.value);
            console.log(e.target.value);
        }
        if(e.target.value==="community"){
            setCommunitySpan(true);
        }else{
            setCommunitySpan(false);
        }
        
    };

    //Removes original file to clean up db
    const getOldFileUrl = () => {
        var imageRef = storage.refFromURL(editEventId.event.photoUrl)
        console.log(imageRef)
        imageRef.delete().then(function(){
            console.log("delete success");
        }).catch(function(error){
            console.log(`Error ${error}`);
        });
        
    }

    const handleRemove = (fileref) => {    
        try{
         if(fileref != ""){
            var file = storage.ref().child(`images/${tempFile}`)
            try {file.delete().then(function(){
                console.log("Main Image deleted");
            }).catch(function(error){
                console.log(`Error ${error}`);
            });
            }catch(error){
                console.log(`Error ${error}`);
            } 
            }

         var randomString = makeid(9)
         setRandomKey(randomString);
         setProgress(0);
         setUpdateImg(false);
        }catch(error){
            console.log(`Error ${error}`);
        }   
    }


     const handleUpdate = () => {
    if(eventTitle != ' ' && start != ' ' && end != ' ' && categoryValue != ' ' && fileUrl != ' ' ){
        if (window.confirm('Are you sure you wish to update this event?')){
            if(updateImg==true){
                getOldFileUrl();
            }
         db.collection("content").doc(editEventId.id).update({
            //  timestamp
             title: eventTitle || editEventId.event.title,
             body: eventBody || editEventId.event.body,
             photoUrl: fileUrl || editEventId.event.photoUrl,
             linkName: linkName || editEventId.event.linkName,
             link: link || editEventId.event.link,
             locationInfo: locationInfo || editEventId.event.locationInfo,
             start: start || editEventId.event.start,
             end: end || editEventId.event.end,
             category: categoryValue || editEventId.event.category,
             subHeader: commSubHeader || editEventId.event.subHeader,
             geopoints: firebase.firestore.FieldValue.arrayUnion(...geopoints) || editEventId.event.geopoints,
             pinned: pin || editEventId.event.pinned,

         });
        } else {
            return false;
        }
        clearInputs();
        var randomString = makeid(9)


         setRandomKey(randomString);
         setProgress(0);
         setUpdateImg(false);
         scrollToTop();
         setErrorURLmessage();
        }else{
            setError("You are missing one or more components.");
            setErrorTip("*");
        }
     };

     

     const removeGeo = (id,lat,long) => {
        console.log(id);
        let array = [editEventId.event.geopoints];
        const obj = {id:id, latitude: lat, longitude: long}
        db.collection("content").doc(editEventId.id).update({
            geopoints: firebase.firestore.FieldValue.arrayRemove(obj)
        });
        
     }
    console.log(pin)

    return (
        <div className="event">
           <div>
               <h2>Edit Event</h2>
               <div>
               <label className="errorMsg">{errorTip}</label>
               <label>Title</label>
               <input className="form-control form-control-lg" type="text" placeholder={`${editEventId.event.title}`} aria-label=".form-control-lg example"
                        // value={eventTitle}
                        maxLength="50"
                        value={eventTitle || editEventId.event.title}
                        onChange={updateTitle}
                    ></input>
                <br></br>
                <div className="mb-3">
                <label>Body</label>
                    <textarea className="form-control" placeholder={`${editEventId.event.body}`} rows="3"
                        // value={eventBody}
                        value={eventBody || editEventId.event.body}
                        onChange={updateBody}
                    ></textarea>
                    </div>
                    <div className="mb-3">
                    <label>Info about Geopoint/Address</label>
                    <textarea className="form-control" placeholder={`${editEventId.event.locationInfo}`} rows="2"
                        value={locationInfo || editEventId.event.locationInfo}
                        onChange={updateLocationInfo}
                    ></textarea>
                    </div>
                    <div>
                   <input className="form-control" type="file" 
                   accept="images/*" 
                   key= {randomKey || ''}
                   onChange={onFileUpdate} />
                   </div>
                   <progress max="100" value={progress}></progress>
                   <br/>
                   <button type="submit" className="btn btn-danger mb-3" onClick={handleRemove}>Cancel</button>
                   <br/>
                <div>
                <label>Link Name</label>
                    <input className="form-control form-control-md" type="text" placeholder={`${editEventId.event.linkName}`} aria-label=".form-control-lg example"
                        maxLength="50"
                        value={linkName || editEventId.event.linkName}
                        onChange={updateLinkName}
                    ></input>
                    <label>Link Url</label>
                     <input className="form-control form-control-md" type="url" pattern="https?://.+" required placeholder={`https:// ${editEventId.event.link}`} aria-label=".form-control-lg example"
                        value={link || editEventId.event.link}
                        // value={link}
                        onChange={updateLink}
                    ></input>
                    <span className="errorMsg">{errorURLmessage}</span>
                    </div>
                    <br></br>
                <div>
                {/* <label className="errorMsg">{errorTip}</label> */}
                    <label>Event Start Date & Time</label>
                    <br/>
                    <label>Current: {`${startDateFormat}`}</label>
            
                    <DateTimePicker className="btn-space form-control-md" onChange={setStart} value={start}/>
                </div>
                <br/>
                <div>
                    {/* <label className="errorMsg">{errorTip}</label> */}
                    <label>Event End Date & Time</label>
                    <br/>
                    <label>Current: {`${endDateFormat}`}</label>
                    <DateTimePicker className="btn-space form-control-md" onChange={setEnd} value={end} />
                </div>
                <br></br>
                {/* TODO: Add Category and subheader information */}
                
                <div value={editEventId.event.category} onChange={handleCategoryChange}>
                <label className="errorMsg">{errorTip}</label>
                {/* <label>Current Value: {`${editEventId.event.category}`}</label> */}
                <label>Current Filter: {filterName}</label>
                <br/>
                <label>Filter</label>
                        <div className="form-check"  >
                            <input className="form-check-input" type="radio" name="category" value="mtrep" />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Montana Repertory Theatre
                            </label>
                            </div>
                            {/* <div className="form-check">
                            <input className="form-check-input" type="radio" name="category" value="friday"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                First Friday </label>
                            </div> */}
                            <div className="form-check">
                            <input className="form-check-input" type="radio" name="category"  value="community" />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Community 
                            </label>
                            </div>
                        </div>
                    </div>
                    {communitySpan == true && (
                         <span>
                        <label>Community SubHeader</label>
                        <input className="form-control form-control-md" type="text" aria-label=".form-control-lg example"
                         maxLength="50"
                         value={commSubHeader || editEventId.event.commSubHeader}
                         placeholder={`${editEventId.event.commSubHeader}`}
                         onChange={updateCommSubHeader}
                    ></input></span>
                    )}

                    <br/>
                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" 
                    // value={pin} 
                    checked={editEventId.event.pinned}
                    onClick={changePin}
                     />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                        Pin this post to the top.
                    </label>
                    </div>
                    <br></br>

                <h6>Geopoints</h6>
                <div>
                {editEventId.event.geopoints.map(geopoint => (
                    <>
                        {(function () {
                             for(let i = 0; i<editEventId.event.geopoints.length;i++){
                                if(geopoint.latitude != `` && geopoint.longitude != ``){
                                    return(
                                        <>
                                        <p>Latitude: {geopoint.latitude} | Longitude: {geopoint.longitude}<button type="button"
                                            onClick={()=> removeGeo(geopoint.id,geopoint.latitude,geopoint.longitude)}>Delete</button></p> 
                                            <hr/>
                                        </>
                                    )
                                }
                            }
                        })()}
                       
                    </>
                ))}

            </div>
                { geopoints.map((geopoint, id)=> (
                    <div className="events__geopoints_div" key={id}>
                        <label>Latitude</label>
                        <input 
                        className="form-control form-control-md events__geopoints"
                        name="latitude" 
                        label="Latitude" 
                        value={geopoint.latitude} 
                        type="number"
                        onChange={(e) => handleChangeInput(id, e)}
                        
                        />
                        <label>Longitude</label>
                        <input  
                        className="form-control form-control-md events__geopoints"
                        name="longitude" 
                        label="Longitude" 
                        value={geopoint.longitude} 
                        type="number"
                        onChange={(e) => handleChangeInput(id, e)}
                        
                        />
                        
                        <a title="Remove" className="btn btn-danger btn-space" 
                        onClick= {()=> handleRemoveFields(id)}>
                            <i className="fas fa-minus"></i>
                        </a>
                        <a title="Add" className="btn btn-success btn-space" 
                        onClick={()=> handleAddFields()}>
                            <i className="fas fa-plus"></i>
                        </a>
                        
                    </div>
                )) }
                
                <br></br>
                <label className="errorMsg">{error}</label>
                <br/>
                    <div className="col-auto">
                        <button type="button" className="btn btn-warning mb-3" onClick={handleUpdate}>Update</button>
                    </div>

               </div>


           </div>
        
    )
 
}
