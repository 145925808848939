import React, {useState} from 'react'
import { db } from '../../services/firebase'

export default function EditUser({editUserId}) {
    const [username, setUsername] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [isAdmin, setIsAdmin] = useState();
    const [isPremium, setIsPremium] = useState();
    const [isSubscribed, setIsSubscribed] = useState();

    const [error, setError]= useState(false);
    const [errorTip, setErrorTip] = useState(false);
    
    if(editUserId == null){
        return null;
    }

    const clearInputs = () => {
        setUsername('');
        setUserEmail('');
        setIsAdmin();
        setIsPremium();
        setIsSubscribed();
        setError(false);
        setErrorTip(false);
    }
    //reset password function?

    const updateName = (e) => {
        if(e.target.value == ''){
            setUsername(' ');
        }
        else{
            setUsername(e.target.value)
        }
    }

    const updateEmail = (e) => {
        if(e.target.value == ''){
            setUserEmail(' ');
        }
        else{
            setUserEmail(e.target.value)
        }
    }

    const changePremium = () => {
        editUserId.User.isPremium=!editUserId.User.isPremium;
        setIsPremium(editUserId.User.isPremium);
    }
    const changeSubscribed = () => {
        editUserId.User.isSubscribed=!editUserId.User.isSubscribed;
        setIsSubscribed(editUserId.User.isSubscribed);
    }
    const changeAdmin = () => {
        editUserId.User.isAdmin=!editUserId.User.isAdmin;
        setIsAdmin(editUserId.User.isAdmin);
    }

    const handleUpdate = (e) => {
        if(userEmail != ' ' & username != ' '){
            if (window.confirm('Are you sure you wish to update this user?')){
                db.collection("users").doc(editUserId.id).update({
                    name: username || editUserId.User.name,
                    email: userEmail || editUserId.User.email,
                    isAdmin: isAdmin || editUserId.User.isAdmin,
                    isPremium: isPremium || editUserId.User.isPremium,
                    isSubscribed: isSubscribed || editUserId.User.isSubscribed
        
                });
            }else {
                return false
            }
            clearInputs();
        }else {
            setError("You are missing one or more components.");
            setErrorTip("*");
        }
      
    };

    return (
        <div className="user">
            <div>
                <h2>Edit Users</h2>
                <label className="errorMsg">{errorTip}</label>
                <label>Name</label>
                <input className="form-control form-control-md" placeholder={`${editUserId.User.name}`}
                    value={username || editUserId.User.name}
                    onChange={updateName}
                ></input>
                <br></br>
                <label className="errorMsg">{errorTip}</label>
                <label>Email</label>
                <input className="form-control form-control-md" placeholder={`${editUserId.User.email}`}
                    value={userEmail || editUserId.User.email}
                    onChange={updateEmail}
                ></input>
                
                <br/>
                <input className="form-check-input" type="checkbox" 
                    checked={editUserId.User.isPremium}
                    onChange={changePremium}
                /> Premium
                <br/>
                <input className="form-check-input" type="checkbox"
                    checked={editUserId.User.isSubscribed}
                    onChange={changeSubscribed}
                /> Subscribed
                <br/>
                <input className="form-check-input" type="checkbox" 
                    checked={editUserId.User.isAdmin}
                    onChange={changeAdmin}
                /> Admin
                <br/>
                <br/>
                <button disabled={true} className="btn btn-primary">Reset Password</button>
                <br></br>
                <label className="errorMsg">{error}</label>
                <br/>
                    <div className="col-auto">
                        <button type="button" className="btn btn-warning mb-3" onClick={handleUpdate}>Update</button>
                    </div>

            </div>
        </div>
    )
}
