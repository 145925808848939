import React from 'react';
import "firebase/firestore";
import { AuthProvider } from './services/AuthContext';
import {
    BrowserRouter as Router,
    Route,
    Switch
  } from 'react-router-dom';
import * as ROUTES from './services/routes';
import Home from "./Home";
import Login from './Login';
import PrivateRoute from "./PrivateRoute"
import ForgotPassword from "./ForgotPassword"
import UpdateProfile from "./UpdateProfile"


import './index.css';

const App = () => {
  return (
      <div>
      <Router>
        <AuthProvider>
          <Switch>
              <PrivateRoute exact path="/" component={Home} />
              <PrivateRoute path="/Home" component={Home} />
              <PrivateRoute path="/update-profile" component={UpdateProfile} />
              <Route exact path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Home/>
          </Switch>
        </AuthProvider>
      </Router> 
      </div> 
  );
};

export default App;